import { z } from 'zod';

export const listTaskValidationsPathSchema = z.object({
  subdomain: z.string(),
  questId: z.string(),
  taskId: z.string(),
});

const EmptyTaskValidationMetadata = z.object({
  input: z.object({
    input: z.record(z.never()),
  }),
});

const SingleValueTaskValidationMetadata = z.object({
  input: z.object({
    value: z.union([z.number(), z.string()]),
  }),
});

const MultipleValueTaskValidationMetadata = z.object({
  input: z.object({
    values: z.array(z.string()),
  }),
});

const FileTaskValidationMetadata = z.object({
  input: z.object({
    fileUrls: z.array(z.string()),
  }),
});

const TweetMetrics = z.object({
  bookmarkCount: z.number().optional(),
  viewCount: z.number().optional(),
  likeCount: z.number().optional(),
  retweetCount: z.number().optional(),
  replyCount: z.number().optional(),
  quoteCount: z.number().optional(),
  updatedAt: z.string().optional(),
});

const TweetCreator = z.object({
  imageUrl: z.string().optional(),
  username: z.string().optional(),
  name: z.string().optional(),
  id: z.string().optional(),
  createdAt: z.string().optional(),
  followerCount: z.number().optional(),
  isVerified: z.boolean().optional(),
});

const TweetMedia = z.object({
  type: z.enum(['photo', 'video', 'animated_gif']),
  url: z.string(),
});

const TweetReactTaskValidationMetadata = z.object({
  input: z.object({
    reply: z.string().optional(),
    tweetId: z.string().optional(), // legacy
    tweetUrl: z.string().optional(),
  }),
  tweetId: z.string().optional(),
  tweetMetrics: TweetMetrics.optional(),
  createdAt: z.string().optional(),
  tweetCreator: TweetCreator.optional(),
  tweetText: z.string().optional(),
  tweetMedia: z.array(TweetMedia).optional(),
});

const TweetTaskValidationMetadata = z.object({
  input: z.object({
    tweet: z.string().optional(),
    tweetId: z.string().optional(), // legacy
    tweetUrl: z.string().optional(),
  }),
  tweetId: z.string().optional(),
  tweetMetrics: TweetMetrics.optional(),
  createdAt: z.string().optional(),
  tweetCreator: TweetCreator.optional(),
  tweetText: z.string().optional(),
  tweetMedia: z.array(TweetMedia).optional(),
});

const TwitterSpaceTaskValidationMetadata = z.object({
  input: z.object({
    password: z.string().optional(),
  }),
});

const ApiTaskValidationMetadata = z.object({
  input: z.object({
    requestId: z.string().optional(),
    response: z.record(z.unknown()).optional(),
    statusCode: z.number().optional(),
  }),
});

const TaskValidationMetadata = z.union([
  EmptyTaskValidationMetadata,
  SingleValueTaskValidationMetadata,
  MultipleValueTaskValidationMetadata,
  FileTaskValidationMetadata,
  TweetReactTaskValidationMetadata,
  TweetTaskValidationMetadata,
  TwitterSpaceTaskValidationMetadata,
  ApiTaskValidationMetadata,
]);

export const listTaskValidationsOutputSchema = z.object({
  hasNextPage: z.boolean(),
  nextPage: z.number().nullable(),
  data: z.array(
    z.object({
      taskId: z.string(),
      claimedQuestId: z.string(),
      userId: z.string(),
      communityId: z.string(),
      taskType: z.string(),
      status: z.enum(['error', 'in-review', 'success']),
      claimStatus: z.enum(['fail', 'pending', 'success']),
      metadata: TaskValidationMetadata,
      createdAt: z.string().nullable(),
      updatedAt: z.string().nullable(),
    }),
  ),
});

const listTaskValidationsSortEnum = z.enum(['tweetViews', 'tweetInteractions', 'tweetCreation']);

export const listTaskValidationsQuerySchema = z.object({
  sortedBy: listTaskValidationsSortEnum.optional(),
});

export const listTaskValidationsInputSchema = listTaskValidationsPathSchema.and(
  listTaskValidationsQuerySchema,
);

export type ListTaskValidationsSort = z.infer<typeof listTaskValidationsSortEnum>;

export type ListTaskValidationsInput = z.infer<typeof listTaskValidationsInputSchema>;
export type ListTaskValidationsOutput = z.infer<typeof listTaskValidationsOutputSchema>;
