import { z } from 'zod';
export const createUserAccountBodySchema = z.object({
// add body
});
export const createUserAccountPathSchema = z.object({
// add path params : have to be the same name as path in TRCP
});
export const createUserAccountInputSchema = createUserAccountPathSchema.merge(createUserAccountBodySchema);
export const createUserAccountOutputSchema = z.object({
// add response
});
