import { setReadStatusBodySchema, setReadStatusPathSchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const setNotificationsReadStatus = mutation({
    method: 'PATCH',
    path: '',
    pathParams: setReadStatusPathSchema,
    body: setReadStatusBodySchema,
    responses: {
        200: noBody(),
    },
});
