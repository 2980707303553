import { router } from '../../utils';
import { getActiveUsers } from './getActiveUsers';
import { getClaimedQuests } from './getClaimedQuests';
import { getClaimedQuestsStatusCount } from './getClaimedQuestsStatusCount';
import { getDiscordMembers } from './getDiscordMembers';
import { getMembers } from './getMembers';
import { getNewMembers } from './getNewMembers';
import { getReferralUrlCount } from './getReferralUrlCount';
import { getTopCommunities } from './getTopCommunities';
import { getTopCountries } from './getTopCountries';
import { getTopQuests } from './getTopQuests';
import { getTopReviewers } from './getTopReviewers';
import { getTotalClaimedQuests } from './getTotalClaimedQuests';
import { getTotalMembers } from './getTotalMembers';
import { getTwitterFollowers } from './getTwitterFollowers';
export const analyticsContract = router({
    getClaimedQuestsStatusCount,
    getTopReviewers,
    getTopCommunities,
    getTopCountries,
    getTopQuests,
    getReferralUrlCount,
    getTotalMembers,
    getTotalClaimedQuests,
    getDiscordMembers,
    getTwitterFollowers,
    getClaimedQuests,
    getNewMembers,
    getActiveUsers,
    getMembers,
}, {
    pathPrefix: '/communities/:subdomain/analytics',
});
