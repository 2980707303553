import { z } from 'zod';
import { dateWithoutTime } from '../common';
export const listPromotionBookingSchema = z.object({
    fullyBookedSlots: z.array(dateWithoutTime).optional(),
    bookings: z.array(dateWithoutTime),
});
export const listPromotionBookingQuerySchema = z.object({
    from: dateWithoutTime.optional(),
    to: dateWithoutTime.optional(),
    includeFullyBookedInformation: z.enum(['true', 'false']).optional().default('true'),
});
