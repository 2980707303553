import { router } from '../../utils';
import { banMember } from './banMember';
import { countInvites } from './countInvites';
import { getMember } from './getMember';
import { listInvites } from './listInvites';
import { setRole } from './setRole';
export const communityMemberContract = router({
    ban: banMember,
    get: getMember,
    listInvites: listInvites,
    countInvites: countInvites,
    setRole: setRole,
}, {
    pathPrefix: '/communities/:subdomain/users',
});
