import { router } from '../../utils';
import { createPartnershipRequest } from './createPartnershipRequest';
import { listPartnershipRequests } from './listPartnershipRequests';
import { searchPartnershipCommunity } from './searchPartnershipCommunity';
import { updatePartnershipRequest } from './updatePartnershipRequest';
export const partnershipContract = router({
    createPartnershipRequest,
    updatePartnershipRequest,
    listPartnershipRequests,
    searchPartnershipCommunity,
}, {
    pathPrefix: '/communities/:subdomain/partnerships',
});
