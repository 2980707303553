import { ofetch } from 'ofetch';
import { config } from './config';
import { clientFactory, forwardedCookie } from './utils';
const $fetch = ofetch.create({
    baseURL: config.baseURL,
    headers: config.headers,
    onRequest(context) {
        context.options.baseURL = config.baseURL;
        const cookie = forwardedCookie();
        const apiKey = context.options.apiKey;
        let headers = {};
        if (Array.isArray(context.options.headers)) {
            headers = Object.fromEntries(context.options.headers);
        }
        else if (context.options.headers instanceof Headers) {
            headers = Object.fromEntries([...context.options.headers.entries()]);
        }
        const additionalHeaders = {
            ...headers,
            ...config.headers,
            ...(apiKey ? { 'X-Api-Key': apiKey } : {}),
            ...(cookie ? { cookie } : {}),
        };
        Object.entries(additionalHeaders).forEach(([k, v]) => {
            context.options.headers.set(k, v);
        });
    },
    credentials: 'include',
});
/**
 * HTTP API Fetch instance
 * @param request
 * @param options
 * @returns
 */
function __api(request, options) {
    return $fetch(request, options);
}
export const api = clientFactory(__api, $fetch);
