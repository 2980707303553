import { getCommunityWebhookEventOutputSchema, getCommunityWebhookEventPathSchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const getCommunityWebhookEvent = query({
    method: 'GET',
    path: '/:subdomain/webhooks/:webhookId/events/:eventId',
    pathParams: getCommunityWebhookEventPathSchema,
    responses: {
        200: getCommunityWebhookEventOutputSchema,
    },
});
