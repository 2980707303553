import { getBillingPortalUrlOutputSchema, getBillingPortalUrlPathSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const getBillingPortalUrl = query({
    method: 'GET',
    path: '/billing-portal',
    pathParams: getBillingPortalUrlPathSchema,
    responses: {
        200: getBillingPortalUrlOutputSchema,
    },
});
