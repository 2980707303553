import { getAnalyticsPathSchema, getRangeAnalyticsQuerySchema, getTopCommunitiesOutputSchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const getTopCommunities = query({
    method: 'GET',
    path: '/top-communities',
    pathParams: getAnalyticsPathSchema,
    query: getRangeAnalyticsQuerySchema,
    responses: {
        200: getTopCommunitiesOutputSchema,
    },
});
