import { z } from 'zod';
export const errors = {
    400: z.object({
        message: z.string(),
        code: z.literal('BAD_REQUEST'),
        context: z.record(z.string()).optional(),
        issues: z
            .array(z.object({
            message: z.string(),
            validation: z.string(),
            code: z.string(),
            location: z.string(),
            path: z.array(z.string()),
        }))
            .optional(),
    }),
    401: z.object({
        message: z.string(),
        code: z.literal('UNAUTHORIZED'),
    }),
    403: z.object({
        message: z.string(),
        code: z.literal('FORBIDDEN'),
    }),
    404: z.object({
        message: z.string(),
        code: z.literal('NOT_FOUND'),
    }),
    409: z.object({
        message: z.string(),
        code: z.literal('CONFLICT'),
    }),
    429: z.object({
        message: z.string(),
        code: z.literal('TOO_MANY_REQUESTS'),
    }),
    500: z.object({
        message: z.string(),
        code: z.literal('INTERNAL_SERVER_ERROR'),
    }),
};
