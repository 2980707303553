import { discordOAuth2CallbackInputSchema, none } from '@zealy/schemas';
import { query } from '../../utils';
export const discordOAuth2Callback = query({
    method: 'GET',
    path: '/oauth2/discord/callback',
    query: discordOAuth2CallbackInputSchema,
    responses: {
        302: none,
    },
});
