import { z } from 'zod';
export const listLeaderboardSprintQuestsQuerySchema = z.object({
    startDate: z.string().optional(),
});
export const listLeaderboardSprintQuestsPathSchema = z.object({
    subdomain: z.string(),
});
export const listLeaderboardSprintQuestsInputSchema = listLeaderboardSprintQuestsPathSchema.merge(listLeaderboardSprintQuestsQuerySchema);
export const listLeaderboardSprintQuestsOutputSchema = z.object({
    data: z.array(z.object({
        id: z.string(),
        title: z.string(),
        quests: z.array(z.object({
            id: z.string(),
            name: z.string(),
            claimed: z.boolean(),
        })),
    })),
});
