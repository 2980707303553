import { z } from 'zod';
export const getClaimedQuestStatusCountOutputSchema = z.object({
    isComputed: z.boolean(),
    data: z
        .object({
        success: z.number(),
        fail: z.number(),
        pending: z.number(),
    })
        .nullable(),
});
