import { z } from 'zod';
import { publicCommunityData } from '../community/common';
export const getUserCommunitiesPathSchema = z.object({
    id: z.string().uuid(),
});
export const getUserCommunitiesQuerySchema = z.object({
    limit: z.coerce.number().default(20),
    page: z.coerce.number().min(0).default(0),
});
export const getUserCommunitiesInputSchema = getUserCommunitiesPathSchema.merge(getUserCommunitiesQuerySchema);
export const getUserCommunitiesOutputSchema = z.object({
    total: z.number(),
    communities: z.array(publicCommunityData),
});
