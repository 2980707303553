import { z } from 'zod';
export const communityWebhookEventType = z.enum([
    'JOINED_COMMUNITY',
    'LEFT_COMMUNITY',
    'QUEST_SUCCEEDED',
    'QUEST_CLAIMED',
    'QUEST_FAILED',
    'QUEST_CLAIM_STATUS_UPDATED',
    'SPRINT_STARTED',
    'SPRINT_ENDED',
    'USER_BANNED',
]);
export const communityWebhookEventStatusType = z.enum([
    'success',
    'failed',
    'pending',
    'exhausted',
]);
