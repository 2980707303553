import { z } from 'zod';

import { questSchema } from './common';

export const listRecommendedQuestsOutputSchema = z.array(
  questSchema.merge(
    z.object({
      communityImageUrl: z.string().nullable(),
      communityName: z.string(),
      subdomain: z.string(),
    }),
  ),
);

export type ListRecommendedQuestsOutput = z.infer<typeof listRecommendedQuestsOutputSchema>;
