import { generateNonceBodySchema, generateNonceOutputSchema } from '@zealy/schemas';
import { mutation } from '../../utils';
export const generateNonce = mutation({
    method: 'POST',
    path: '/nonce',
    body: generateNonceBodySchema,
    responses: {
        200: generateNonceOutputSchema,
    },
});
