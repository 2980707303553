import { z } from 'zod';
export const listModulesPathSchema = z.object({
    subdomain: z.string(),
});
export const listModulesOutputSchema = z.array(z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().nullable(),
    color: z.string().nullable(),
    coverUrl: z.string().nullable(),
    position: z.number(),
}));
