import { partnershipsRequestOutputSchema, partnershipsRequestQuerySchema } from '@zealy/schemas';
import { query } from '../../utils';
export const listPartnershipRequests = query({
    method: 'GET',
    path: '',
    query: partnershipsRequestQuerySchema,
    responses: {
        200: partnershipsRequestOutputSchema,
    },
});
