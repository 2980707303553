import { z } from 'zod';
import { augmentedTokenSchema, nonEmptyStringSchema } from '@zealy/utils';
export const updateTokenRewardTransactionSchema = z
    .object({
    address: z.string(),
    contract: z.string(),
    createdAt: z.string(),
    id: z.string(),
    network: z.string(),
    questId: z.string(),
    status: z.string(),
    transactionId: z.string().optional().nullable(),
})
    .merge(augmentedTokenSchema);
export const updateTokenRewardTransactionBodySchema = z.object({
    transactionId: nonEmptyStringSchema,
});
