import { z } from 'zod';
import { publicCommunityData } from './common';
const categorySchema = z.enum(['upcoming', 'all', 'featured']);
export const listCommunityQuerySchema = z.object({
    category: categorySchema,
});
export const listCommunityInputSchema = listCommunityQuerySchema;
export const listCommunityOutputSchema = z.object({
    communities: z.array(publicCommunityData),
});
