import { disconnectUserWalletBodySchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const disconnectUserWallet = mutation({
    method: 'PATCH',
    path: '/me/wallet',
    body: disconnectUserWalletBodySchema,
    responses: {
        200: noBody(),
    },
});
