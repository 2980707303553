import { router } from '../../utils';
import { createWalletSession } from './createWalletSession';
import { discordOAuth2Callback } from './discordOAuth2Callback';
import { generateNonce } from './generateNonce';
import { generateTonPayload } from './generateTonPayload';
import { impersonateUser } from './impersonateUser';
import { otpLogin } from './otpLogin';
import { redirectOAuth2 } from './redirectOAuth2';
import { sendOtp } from './sendOtp';
import { tiktokOAuth2Callback } from './tiktokOAuth2Callback';
import { twitterOAuth2Callback } from './twitterOAuth2Callback';
import { verifySignature } from './verifySignature';
import { verifyTonProof } from './verifyTonProof';
import { walletLogin } from './walletLogin';
export const authContract = router({
    otpLogin: otpLogin,
    sendOtp: sendOtp,
    walletLogin: walletLogin,
    generateNonce: generateNonce,
    createWalletSession: createWalletSession,
    verifySignature: verifySignature,
    redirectOAuth2: redirectOAuth2,
    discordOAuth2Callback: discordOAuth2Callback,
    twitterOAuth2Callback: twitterOAuth2Callback,
    tiktokOAuth2Callback: tiktokOAuth2Callback,
    verifyTonProof: verifyTonProof,
    generateTonPayload: generateTonPayload,
    impersonate: impersonateUser,
}, {
    pathPrefix: '/authentication',
});
