import { uniq } from 'lodash';
import { z } from 'zod';
import { networkSchema } from '@zealy/utils';
export const USERNAME_REGEXP = new RegExp('^[a-zA-Z0-9._]{3,30}$');
const INTERESTS = [
    'work',
    'ai',
    'gaming',
    'web3',
    'music',
    'design',
    'fashion',
    'health',
    'marketing',
    'finance',
    'engineering',
];
const validateDisplayedInformation = (displayInformation) => {
    const array = JSON.parse(displayInformation);
    if (!Array.isArray(array)) {
        throw new Error('displayedInformation must be an array');
    }
    if (array.some(e => !['wallet', 'discord', 'twitter'].includes(e))) {
        throw new Error('Invalid displayedInformation');
    }
    if (uniq(array).length !== array.length) {
        throw new Error('Duplicate displayedInformation');
    }
    return array;
};
const validateInterests = (interests) => {
    const array = JSON.parse(interests);
    if (!Array.isArray(array)) {
        throw new Error('Interests must be an array');
    }
    if (array.some(e => !INTERESTS.includes(e))) {
        throw new Error('Invalid interests');
    }
    if (uniq(array).length !== array.length) {
        throw new Error('Duplicate interests');
    }
    return array;
};
export const updateUserBodySchema = z.object({
    username: z.string().regex(USERNAME_REGEXP).optional(),
    goal: z.string().nullable().optional(),
    blockchain: z.string().optional(),
    address: z.string().nullable().optional(),
    country: z.string().optional(),
    city: z.string().optional(),
    displayedInformation: z.string().refine(validateDisplayedInformation).optional(),
    referrer: z.string().nullable().optional(),
    interests: z.string().refine(validateInterests).optional(),
});
export const updateUserPathSchema = z.object({
    id: z.string().uuid(),
});
export const disconnectUserWalletBodySchema = z.object({
    blockchain: networkSchema,
});
export const updateUserInputSchema = updateUserPathSchema.merge(updateUserBodySchema);
export const updateUserOutputSchema = z.object({
// add response
});
