import { z } from 'zod';
const patchReviewsBodySchema = z.object({
    claimedQuestIds: z.array(z.string().uuid()),
    status: z.enum(['success', 'fail']),
    mark: z.enum(['flag', 'star']).optional(),
    comment: z.string().optional(),
    bonusXP: z.number().optional(),
});
export const patchReviewsInputSchema = patchReviewsBodySchema;
export const patchReviewsOutputSchema = z.object({
    reviewedQuests: z.number(),
    status: z.enum(['success', 'fail']),
    comment: z.string().optional(),
    mark: z.enum(['flag', 'star']).optional(),
    rejectedQuests: z
        .array(z.object({
        ids: z.array(z.string().uuid()),
        reason: z.string(),
    }))
        .optional(),
});
