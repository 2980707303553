import { z } from 'zod';
export const updateUserAccountBodySchema = z.object({
// add body
});
export const updateUserAccountPathSchema = z.object({
    id: z.string().uuid(),
});
export const updateUserAccountInputSchema = updateUserAccountPathSchema.merge(updateUserAccountBodySchema);
export const updateUserAccountOutputSchema = z.object({
// add response
});
