import { z } from 'zod';
export const createUserApiKeyHashBodySchema = z.object({
// add body
});
export const createUserApiKeyHashPathSchema = z.object({
// add path params : have to be the same name as path in TRCP
});
export const createUserApiKeyHashInputSchema = createUserApiKeyHashPathSchema.merge(createUserApiKeyHashBodySchema);
export const createUserApiKeyHashOutputSchema = z.object({
// add response
});
