import { z } from 'zod';
export const getReferralUrlCountOutputSchema = z.object({
    isComputed: z.boolean(),
    data: z
        .array(z.object({
        url: z.string(),
        number: z.number(),
    }))
        .nullable(),
});
