import { getUserByFieldOutputSchema, getUserByFieldSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const getUserByField = query({
    method: 'GET',
    path: '',
    query: getUserByFieldSchema,
    responses: {
        200: getUserByFieldOutputSchema,
    },
});
