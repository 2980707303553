import { isBotOutputSchema, isBotPathSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const isBot = query({
    method: 'GET',
    path: '/:identityId/check',
    pathParams: isBotPathSchema,
    responses: {
        200: isBotOutputSchema,
    },
});
