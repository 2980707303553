import { z } from 'zod';
import { augmentedTokenSchema, decimalSchema } from '@zealy/utils';
import { reviewSchema } from '../reviews';
import { tokenRewardsSchema } from '../tokenReward';
export const getRewardsPathSchema = z.object({
    subdomain: z.string(),
    questId: z.string().uuid(),
});
export const getRewardsInputSchema = getRewardsPathSchema;
const userRewardSchema = z.object({
    id: z.string(),
    name: z.string().nullish(),
    avatar: z.string().nullish(),
    createdAt: z.string().datetime(),
    tokenReward: tokenRewardsSchema.merge(augmentedTokenSchema).optional().nullable(),
    claimedQuest: reviewSchema.omit({ quest: true, autoValidated: true }).optional(),
});
const rewardMethodSchema = z
    .discriminatedUnion('type', [
    z.object({
        type: z.literal('all'),
    }),
    z.object({
        type: z.literal('raffle'),
        amount: z.number().optional(),
        raffleIsOver: z.boolean(),
        endDate: z.string().optional(),
    }),
    z.object({
        type: z.literal('fcfs'),
        max: z.number().optional(),
    }),
    z.object({
        type: z.literal('top'),
        topN: z.number().optional(),
        rankBy: z.enum(['tweetViews', 'tweetInteractions']).optional(),
        isQuestOver: z.boolean(),
        endDate: z.string().optional(),
    }),
    z.object({
        type: z.literal('vote'),
        max: z.number().optional(),
    }),
])
    .and(z.object({
    id: z.string(),
}));
const tokenRewardTypeSchema = z
    .object({
    type: z.literal('token'),
    value: decimalSchema,
})
    .merge(augmentedTokenSchema);
const rewardTypeSchema = z.discriminatedUnion('type', [
    z.object({
        type: z.literal('xp'),
        value: z.number(),
    }),
    z.object({
        type: z.literal('role'),
        value: z.string(),
        roleName: z.string().optional(),
    }),
    z.object({
        type: z.literal('other'),
        value: z.string(),
    }),
    tokenRewardTypeSchema,
]);
const rewardSchema = z.object({
    reward: rewardTypeSchema
        .and(z.object({ method: rewardMethodSchema }))
        .and(z.object({ isDeleted: z.boolean().optional(), hasPendingClaims: z.boolean().optional() })),
    users: z.array(userRewardSchema),
});
export const getRewardsOutputSchema = z.object({
    data: z.array(rewardSchema),
});
