import { getUserStatsOutputSchema, getUserStatsPathSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const getUserStats = query({
    summary: 'Get number of flags and stars for a user in a community',
    method: 'GET',
    path: '/users/:id',
    pathParams: getUserStatsPathSchema,
    responses: {
        200: getUserStatsOutputSchema,
    },
});
