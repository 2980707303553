import { createAntibotEventBodySchema, createAntibotEventOutputSchema } from '@zealy/schemas';
import { mutation } from '../../utils';
export const createEvent = mutation({
    method: 'POST',
    path: '/events',
    body: createAntibotEventBodySchema,
    responses: {
        200: createAntibotEventOutputSchema,
    },
});
