import { xpBodySchema, xpPathSchema, xpResponseSchema } from '@zealy/schemas';
import { mutation } from '../../utils';
export const removeXp = mutation({
    summary: 'Remove XP from a specific user',
    method: 'DELETE',
    path: '/:userId/xp',
    pathParams: xpPathSchema,
    body: xpBodySchema,
    responses: {
        200: xpResponseSchema,
    },
});
