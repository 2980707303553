import { z } from 'zod';

import { reviewSchema } from './common';

export const getReviewPathSchema = z.object({
  id: z.string().uuid(),
  subdomain: z.string(),
});

export const getReviewInputSchema = getReviewPathSchema;

export const getReviewOutputSchema = reviewSchema;

export type GetReviewInput = z.infer<typeof getReviewInputSchema>;
export type GetReviewOutput = z.infer<typeof getReviewOutputSchema>;
