import { listCommunityWebhookEventsOutputSchema, listCommunityWebhookEventsPathSchema, listCommunityWebhookEventsQuerySchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const listCommunityWebhookEvents = query({
    summary: 'List events a webhook is subscribed to',
    method: 'GET',
    path: '/:subdomain/webhooks/:webhookId/events',
    pathParams: listCommunityWebhookEventsPathSchema,
    query: listCommunityWebhookEventsQuerySchema,
    responses: {
        200: listCommunityWebhookEventsOutputSchema,
    },
});
