import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
export const getMe = () => {
    let metadata;
    if (process.env.NEXT_RUNTIME) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const cookieStore = require('next/headers').cookies();
        metadata = cookieStore.get('user_metadata')?.value;
    }
    else {
        metadata = Cookies.get('user_metadata');
    }
    if (!metadata) {
        // should never happen
        return undefined;
    }
    const decrypted = jwt.decode(metadata);
    return decrypted?.userId;
};
export const forwardedCookie = () => {
    if (process.env.NEXT_RUNTIME) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const cookieStore = require('next/headers').cookies();
        let cookies = '';
        const access_token = cookieStore.get('access_token')?.value;
        const partial_access_token = cookieStore.get('partial_access_token')?.value;
        const user_metadata = cookieStore.get('user_metadata')?.value;
        const partial_user_metadata = cookieStore.get('partial_user_metadata')?.value;
        if (access_token) {
            cookies = `access_token=${access_token};`;
        }
        if (partial_access_token) {
            cookies = `${cookies}partial_access_token=${partial_access_token};`;
        }
        if (user_metadata) {
            cookies = `${cookies}user_metadata=${user_metadata};`;
        }
        if (partial_user_metadata) {
            cookies = `${cookies}partial_user_metadata=${partial_user_metadata};`;
        }
        return cookies;
    }
};
// HTTP client factory
export function clientFactory(__api, $fetch) {
    const cache = new Map();
    const _api = new Proxy(__api, {
        apply(target, thisArg, args) {
            return __api(...args);
        },
        get(_, method) {
            const targetMethod = method;
            if (!cache.has(targetMethod)) {
                cache.set(targetMethod, function api(request, options) {
                    return $fetch(request, {
                        ...options,
                        method: targetMethod.toUpperCase(),
                    });
                });
            }
            return cache.get(targetMethod);
        },
    });
    return _api;
}
