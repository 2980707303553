import { verifySignatureBodySchema, verifySignatureOutputSchema } from '@zealy/schemas';
import { mutation } from '../../utils';
export const verifySignature = mutation({
    method: 'POST',
    path: '/verify-signature',
    body: verifySignatureBodySchema,
    responses: {
        200: verifySignatureOutputSchema,
    },
});
