import { otpLoginBodySchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const otpLogin = mutation({
    method: 'POST',
    path: '/otp/verify',
    body: otpLoginBodySchema,
    responses: {
        200: noBody(),
    },
});
