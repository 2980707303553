import { z } from 'zod';
import { publicCommunityData } from './common';
export const getCommunityInputPathSchema = z.object({
    subdomain: z.string(),
});
export const getCommunityInputSchema = getCommunityInputPathSchema;
export const getCommunityOutputSchema = publicCommunityData.merge(z.object({
    customerId: z.string().nullable().optional(),
    billingEmail: z.string().nullable().optional(),
    planId: z.string().nullable().optional(),
    discordBotEnabled: z.boolean().optional(),
}));
