import { createReviewBodySchema, createReviewOutputSchema, createReviewPathSchema, } from '@zealy/schemas';
import { mutation } from '../../utils';
export const createReview = mutation({
    method: 'POST',
    path: '',
    pathParams: createReviewPathSchema,
    body: createReviewBodySchema,
    responses: {
        200: createReviewOutputSchema,
    },
});
