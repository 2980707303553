import { listModulesOutputSchema, listModulesPathSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const listModules = query({
    summary: 'List modules in a community',
    method: 'GET',
    path: '',
    pathParams: listModulesPathSchema,
    responses: {
        200: listModulesOutputSchema,
    },
});
