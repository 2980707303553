import { resumeSubscriptionPathSchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const resumeSubscription = mutation({
    method: 'POST',
    path: '/resume',
    pathParams: resumeSubscriptionPathSchema,
    body: noBody(),
    responses: {
        200: noBody(),
    },
});
