import { listQuestsOutputSchema, listQuestsPathSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const listQuests = query({
    summary: 'List quests in a community',
    method: 'GET',
    path: '',
    pathParams: listQuestsPathSchema,
    responses: {
        200: listQuestsOutputSchema,
    },
});
