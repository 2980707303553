import { z } from 'zod';

export const testApiQuestEndpointPathSchema = z.object({
  subdomain: z.string(),
});

export const testApiQuestEndpointBodySchema = z.object({
  requestId: z.string(),
  userId: z.string(),
  communityId: z.string(),
  questId: z.string(),
  accounts: z.object({
    discord: z
      .object({
        handle: z.string(),
        id: z.string(),
      })
      .optional(),
    twitter: z
      .object({
        username: z.string(),
        id: z.string(),
      })
      .optional(),
    wallet: z.string().optional(),
    email: z.string().optional(),
    'zealy-connect': z.string().optional(),
  }),
  apiKey: z.string().optional(),
  endpoint: z.string().url(),
});

export const testApiQuestEndpointInputSchema = testApiQuestEndpointPathSchema.merge(
  testApiQuestEndpointBodySchema,
);

export const testApiQuestEndpointOutputSchema = z.object({
  data: z.unknown(),
  status: z.number(),
});

export type TestApiQuestEndpointInput = z.infer<typeof testApiQuestEndpointInputSchema>;
export type TestApiQuestEndpointOutput = z.infer<typeof testApiQuestEndpointOutputSchema>;
