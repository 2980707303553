import { tiktokOAuth2CallbackInputSchema, none } from '@zealy/schemas';
import { query } from '../../utils';
export const tiktokOAuth2Callback = query({
    method: 'GET',
    path: '/oauth2/tiktok/callback',
    query: tiktokOAuth2CallbackInputSchema,
    responses: {
        302: none,
    },
});
