import { z } from 'zod';
import { networkSchema } from '@zealy/utils';
const messageSchema = z.object({
    domain: z.string(),
    address: z.string(),
    statement: z.string().optional(),
    uri: z.string(),
    version: z.string(),
    nonce: z.string(),
    params: z.string().optional(),
    issuedAt: z.string().optional(),
    expirationTime: z.string().optional(),
    chainId: z.number().optional(),
});
export const verifySignatureBodySchema = z.object({
    signature: z.string(),
    network: networkSchema,
    message: messageSchema,
});
export const verifySignatureInputSchema = verifySignatureBodySchema;
export const verifySignatureOutputSchema = z.boolean();
