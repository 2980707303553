import { getUserOutputSchema, getUserPathSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const getUser = query({
    method: 'GET',
    path: '/:id',
    pathParams: getUserPathSchema,
    responses: {
        200: getUserOutputSchema,
    },
});
