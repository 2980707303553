import { updateQuestInputSchema, updateQuestOutputSchema } from '@zealy/schemas';
import { mutation } from '../../utils';
export const updateQuest = mutation({
    summary: 'Update quest in a community',
    method: 'PATCH',
    path: '/:questId',
    body: updateQuestInputSchema,
    responses: {
        200: updateQuestOutputSchema,
    },
});
