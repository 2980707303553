import { z } from 'zod';

import { questSchema } from './common';

export const listQuestsPathSchema = z.object({
  subdomain: z.string(),
});

export const listQuestsOutputSchema = z.array(questSchema);

export const listQuestsInputSchema = listQuestsPathSchema;

export type ListQuestsInput = z.infer<typeof listQuestsInputSchema>;
export type ListQuestsOutput = z.infer<typeof listQuestsOutputSchema>;
