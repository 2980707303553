import { z } from 'zod';
import { communityWebhookEventType } from './common';
export const listCommunityWebhooksPathSchema = z.object({
    subdomain: z.string(),
});
export const listCommunityWebhooksInputSchema = listCommunityWebhooksPathSchema;
export const listCommunityWebhooksOutputSchema = z.object({
    items: z.array(z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
        uri: z.string().url(),
        communityId: z.string().uuid(),
        createdAt: z.string().datetime().nullable(),
        updatedAt: z.string().datetime().nullable(),
        active: z.boolean(),
        secret: z.string(),
        events: z.array(communityWebhookEventType),
        success: z.number(),
        failed: z.number(),
        exhausted: z.number(),
    })),
});
