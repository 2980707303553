import { z } from 'zod';

import { postQuestInputSchema, postQuestOutputSchema } from '@zealy/utils';

export const createQuestInputSchema = postQuestInputSchema;

export const createQuestOutputSchema = postQuestOutputSchema;

export type CreateQuestInput = z.infer<typeof createQuestInputSchema>;

export type CreateQuestOutput = z.infer<typeof createQuestOutputSchema>;

export const createQuestQuerySchema = z.object({
  generated: z.literal('true').optional(),
});
