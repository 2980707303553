import { createCommunityWebhookBodySchema, createCommunityWebhookOutputSchema, createCommunityWebhookPathSchema, } from '@zealy/schemas';
import { mutation } from '../../utils';
export const createCommunityWebhook = mutation({
    method: 'POST',
    path: '/:subdomain/webhooks',
    pathParams: createCommunityWebhookPathSchema,
    body: createCommunityWebhookBodySchema,
    responses: {
        200: createCommunityWebhookOutputSchema,
    },
});
