import { z } from 'zod';
export const generateMagicQuestSuggestionsPathSchema = z.object({
    subdomain: z.string(),
});
export const generateMagicQuestSuggestionsBodySchema = z.object({
    description: z.string(),
    useCase: z.string(),
});
export const generateMagicQuestSuggestionsInputSchema = generateMagicQuestSuggestionsPathSchema.merge(generateMagicQuestSuggestionsBodySchema);
export const generateMagicQuestSuggestionsOutputSchema = z.object({
    suggestions: z.array(z.string()),
});
