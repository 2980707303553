import { updateCommunityWebhookBodySchema, updateCommunityWebhookPathSchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const updateCommunityWebhook = mutation({
    method: 'PATCH',
    path: '/:subdomain/webhooks/:webhookId',
    pathParams: updateCommunityWebhookPathSchema,
    body: updateCommunityWebhookBodySchema,
    responses: {
        200: noBody(),
    },
});
