import { deleteLeaderboardSprintPathSchema, deleteLeaderboardSprintQuerySchema, } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const deleteLeaderboardSprint = mutation({
    method: 'DELETE',
    path: '/communities/:subdomain/leaderboard/sprint',
    pathParams: deleteLeaderboardSprintPathSchema,
    query: deleteLeaderboardSprintQuerySchema,
    body: noBody(),
    responses: {
        200: noBody(),
    },
});
