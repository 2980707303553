import { z } from 'zod';
export const getSubscriptionStatusPathSchema = z.object({
    subdomain: z.string(),
});
export const getSubscriptionStatusInputSchema = getSubscriptionStatusPathSchema;
export const getSubscriptionStatusOutputSchema = z.object({
    isLimitReached: z.boolean(),
    resetAt: z.string().datetime(),
    canAccessFreeTrial: z.boolean(),
});
