import { z } from 'zod';
export const getPricingTablePathSchema = z.object({
    subdomain: z.string(),
});
export const getPricingTableInputSchema = getPricingTablePathSchema;
const tierSchema = z.object({
    unitAmount: z.number().nullable(),
    upTo: z.number().nullable(),
});
export const getPricingTableOutputSchema = z.object({
    data: z.array(z.object({
        name: z.string(),
        description: z.string().nullable(),
        features: z.array(z.string()),
        type: z.enum(['free', 'enterprise', 'regular']),
        isCurrentPlan: z.boolean(),
        priceId: z.string().optional(),
        basePriceId: z.string().optional(),
        yearlyPriceId: z.string().optional(),
        yearlyBasePriceId: z.string().optional(),
        basePrice: z.number().nullable(),
        yearlyBasePrice: z.number().nullable(),
        metadata: z.record(z.string(), z.string()),
        tiers: z.array(tierSchema).optional(),
        yearlyTiers: z.array(tierSchema).optional(),
        freeTrial: z.number().optional(),
        interval: z.enum(['month', 'year', 'day', 'week']),
        planId: z.string().optional(),
        currentBasePrice: z.string().nullish(),
        currentYearlyBasePrice: z.string().nullish(),
    })),
});
