import { z } from 'zod';
import { urlPattern } from '@zealy/utils';
export const generateDetailsInputSchema = z.object({
    url: z.string().regex(urlPattern, { message: 'Invalid URL' }),
});
export const generateDetailsOutputSchema = z.object({
    name: z.string().nullable(),
    description: z.string().nullable(),
    imageUrl: z.string().nullable(),
});
