import { sign } from 'jsonwebtoken';
const headers = {
    Accept: 'application/json',
    'x-next-app-key': process.env.JWT_PRIVATE_KEY
        ? sign({ type: 'NEXTJS_APP_PERMISSION_TOKEN' }, process.env.JWT_PRIVATE_KEY)
        : '',
};
export const config = {
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers,
};
export const configV2 = {
    baseURL: process.env.NEXT_PUBLIC_API_V2_URL,
    headers,
};
export function updateConfigs(options) {
    Object.assign(config, options.api);
    Object.assign(configV2, options.apiV2);
}
