import { getAnalyticsPathSchema, getRangeAnalyticsQuerySchema, getReferralUrlCountOutputSchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const getReferralUrlCount = query({
    method: 'GET',
    path: '/referral-url-count',
    pathParams: getAnalyticsPathSchema,
    query: getRangeAnalyticsQuerySchema,
    responses: {
        200: getReferralUrlCountOutputSchema,
    },
});
