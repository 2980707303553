import { z } from 'zod';
import { baseSprintSchema } from './common';
const sprintSchema = baseSprintSchema.omit({ createdAt: true, startingAt: true, v2: true }).extend({
    startingAt: z.string().datetime().nullable(),
    communityId: z.string().uuid(),
    communityName: z.string().nullable(),
    communitySubdomain: z.string().nullable(),
    communityImage: z.string().nullable(),
    participants: z.number(),
});
export const listSprintsInputSchema = z.object({});
export const listSprintsOutputSchema = z.object({ data: z.array(sprintSchema) });
