import { z } from 'zod';
export const setReadStatusBodySchema = z.object({
    ids: z.array(z.string().uuid()).optional(),
    read: z.boolean(),
});
export const setReadStatusPathSchema = z.object({
    userId: z.string().uuid(),
    subdomain: z.string(),
});
export const setReadStatusInputSchema = setReadStatusPathSchema.merge(setReadStatusBodySchema);
