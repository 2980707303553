import { z } from 'zod';
export const searchUserCommunitiesPathSchema = z.object({
    id: z.string().uuid(),
});
export const searchUserCommunitiesQuerySchema = z.object({
    search: z.string(),
});
export const searchUserCommunitiesInputSchema = searchUserCommunitiesPathSchema.merge(searchUserCommunitiesQuerySchema);
export const searchUserCommunitiesOutputSchema = z.object({
    communities: z.array(z.object({
        id: z.string(),
        name: z.string().nullable(),
        subdomain: z.string().nullable(),
        image: z.string().nullable(),
    })),
});
