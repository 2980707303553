import { walletLoginBodySchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const walletLogin = mutation({
    method: 'POST',
    path: '/wallet/verify-signature',
    body: walletLoginBodySchema,
    responses: {
        200: noBody(),
    },
});
