import { getSubscriptionOutputSchema, getSubscriptionPathSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const getSubscription = query({
    method: 'GET',
    path: '',
    pathParams: getSubscriptionPathSchema,
    responses: {
        200: getSubscriptionOutputSchema,
    },
});
