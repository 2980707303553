import { countInvitesOutputSchema, countInvitesPathSchema, countInvitesQuerySchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const countInvites = query({
    method: 'GET',
    path: '/:userId/invites/count',
    pathParams: countInvitesPathSchema,
    query: countInvitesQuerySchema,
    responses: {
        200: countInvitesOutputSchema,
    },
});
