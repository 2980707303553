import { z } from 'zod';

import {
  apiTaskSchema,
  baseFileTaskSchema,
  baseInstructionSettingsSchema,
  baseInvitesTaskSchema,
  baseOpinionTaskSchema,
  baseTweetReactTaskSchema,
  dateTaskSchema,
  discordTaskSchema,
  fileTaskSettingsTypeRestrictionSchema,
  numberTaskSchema,
  pollTaskSchema,
  positiveIntSchema,
  postQuestTwitterSpaceTaskSchema,
  quizTaskSchema,
  scaleTypeSchema,
  telegramTaskSchema,
  textTaskSchema,
  tiktokTaskSchema,
  tweetTaskSchema,
  twitterFollowTaskSchema,
  twitterTaskSettingsReactEnum,
  urlTaskSchema,
  visitLinkTaskSchema,
} from '@zealy/utils';

import { tipTapDocumentSchema } from '../common';
import { reviewSchema } from '../reviews';

const tweetReactTaskSchema = baseTweetReactTaskSchema.extend({
  settings: z.object({
    actions: z.array(twitterTaskSettingsReactEnum).min(1),
    tweetUrl: z.string(),
  }),
});

export const listQuestsPathSchema = z.object({
  subdomain: z.string(),
});

export const fileTaskSchema = baseFileTaskSchema.extend({
  settings: fileTaskSettingsTypeRestrictionSchema.and(
    z.object({
      autoValidated: z.boolean().optional().default(false),
      maxFileCount: positiveIntSchema,
      maxFileSize: positiveIntSchema,
    }),
  ),
});

export const invitesTaskSchema = baseInvitesTaskSchema.extend({
  settings: z.object({
    minInviteUserCount: z.number().int().positive().max(1000),
  }),
});

const opinionScaleTypeSettingsSchema = z.discriminatedUnion('scaleType', [
  z.object({
    scaleType: z.literal(scaleTypeSchema.enum.numerical),
    step: z.object({
      start: z.number().int().min(-10),
      end: z.number().int().max(100),
    }),
    label: z
      .object({
        start: z.string().optional(),
        end: z.string().optional(),
        middle: z.string().optional(),
      })
      .optional(),
  }),
  z.object({
    scaleType: z.literal(scaleTypeSchema.enum.stars),
    starCount: positiveIntSchema,
  }),
]);

export const opinionTaskSchema = baseOpinionTaskSchema.extend({
  settings: baseInstructionSettingsSchema.and(opinionScaleTypeSettingsSchema),
});

const tasksSchema = z.union([
  apiTaskSchema,
  textTaskSchema,
  discordTaskSchema,
  urlTaskSchema,
  telegramTaskSchema,
  invitesTaskSchema,
  quizTaskSchema,
  visitLinkTaskSchema,
  fileTaskSchema,
  dateTaskSchema,
  numberTaskSchema,
  pollTaskSchema,
  opinionTaskSchema,
  twitterFollowTaskSchema,
  postQuestTwitterSpaceTaskSchema,
  tweetReactTaskSchema,
  tweetTaskSchema,
  tiktokTaskSchema,
]);

const baseId = z.object({
  id: z.string().optional(),
  metadata: z.any().optional(),
});

export const questSchema = z.object({
  description: tipTapDocumentSchema.nullable(),
  id: z.string(),
  name: z.string(),
  deleted: z.boolean().optional(),
  communityId: z.string().optional(),
  categoryId: z.string().optional(),
  createdAt: z.string().datetime().optional(),
  updatedAt: z.string().datetime().optional(),
  archived: z.boolean().nullable(),
  autoValidate: z.boolean().optional(),
  conditionOperator: z.enum(['AND', 'OR']).nullable(),
  conditions: z.array(
    z.object({
      type: z.enum(['level', 'role', 'nft', 'quest', 'date']),
      value: z.union([z.string(), z.number()]),
      operator: z.enum(['=', '>', '<', '>=', '<=', '!=', 'isCompleted', 'isNotCompleted']),
    }),
  ),
  published: z.boolean(),
  recurrence: z.enum(['daily', 'weekly', 'monthly', 'once']),
  retryAfter: z.number().nullable(),
  rewards: z
    .array(
      z.object({
        type: z.enum(['xp', 'role', 'other']),
        operator: z
          .enum(['=', '>', '<', '>=', '<=', '!=', 'isCompleted', 'isNotCompleted'])
          .optional(),
        value: z.union([z.string(), z.number()]),
        chain: z.enum(['polygon', 'ethereum']).optional(),
        collection: z.string().optional(),
        contractAddress: z.string().optional(),
      }),
    )
    .nullable(),
  tasks: z.array(z.intersection(baseId, tasksSchema)),
  claimLimit: z.number().nullish(),
  claimCounter: z.number().nullish(),
});

export const voteItem = reviewSchema.merge(
  z.object({
    voteStatus: z.enum(['upvote', 'downvote']).optional(),
  }),
);
