import { listCommunityOutputSchema, listCommunityQuerySchema } from '@zealy/schemas';
import { query } from '../../utils';
export const listCommunity = query({
    method: 'GET',
    path: '',
    query: listCommunityQuerySchema,
    responses: {
        200: listCommunityOutputSchema,
    },
});
