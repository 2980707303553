import { z } from 'zod';
const actionSchema = z.enum(['upvote', 'downvote', 'cancel']);
export const voteEventsPathSchema = z.object({
    subdomain: z.string(),
    questId: z.string().uuid(),
    rewardId: z.string().uuid(),
});
export const voteEventsInputSchema = z.object({
    action: actionSchema,
    claimQuestId: z.string().uuid(),
});
export const voteEventsOutputSchema = z.object({
    createdAt: z.string().nullable(),
    id: z.string().uuid(),
    communityId: z.string(),
    updatedAt: z.string().nullable(),
    rewardId: z.string().uuid(),
    action: actionSchema,
    questId: z.string().uuid(),
    userId: z.string().uuid(),
    votedClaimedQuestId: z.string().uuid(),
});
