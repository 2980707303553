import { z } from 'zod';

export const getUserStatsPathSchema = z.object({
  id: z.string().uuid(),
  subdomain: z.string(),
});

export const getUserStatsInputSchema = getUserStatsPathSchema;

export const getUserStatsOutputSchema = z.object({
  flags: z.number(),
  stars: z.number(),
});

export type GetUserStatsInput = z.infer<typeof getUserStatsInputSchema>;
export type GetUserStatsOutput = z.infer<typeof getUserStatsOutputSchema>;
