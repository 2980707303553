import { listNotificationsOutputSchema, listNotificationsPathSchema, listNotificationsQuerySchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const listNotifications = query({
    method: 'GET',
    path: '',
    pathParams: listNotificationsPathSchema,
    query: listNotificationsQuerySchema,
    responses: {
        200: listNotificationsOutputSchema,
    },
});
