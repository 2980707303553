import type { z } from 'zod';

import { patchQuestInputSchema, patchQuestOutputSchema } from '@zealy/utils';

export const updateQuestInputSchema = patchQuestInputSchema;

export const updateQuestOutputSchema = patchQuestOutputSchema;

export type UpdateQuestInput = z.infer<typeof updateQuestInputSchema>;

export type UpdateQuestOutput = z.infer<typeof updateQuestOutputSchema>;
