import { voteEventsInputSchema, voteEventsOutputSchema, voteEventsPathSchema, } from '@zealy/schemas';
import { mutation } from '../../utils';
export const createVoteEvents = mutation({
    method: 'POST',
    path: '/:questId/rewards/:rewardId/votes',
    pathParams: voteEventsPathSchema,
    body: voteEventsInputSchema,
    responses: {
        201: voteEventsOutputSchema,
    },
});
