import { z } from 'zod';
import { apiTaskSchema, augmentedDiscordTaskSchema, augmentedNftTaskSchema, augmentedTelegramTaskSchema, augmentedTokenTaskSchema, augmentedTweetReactTaskSchema, augmentedTwitterFollowTaskSchema, augmentedTwitterSpaceTaskSchema, claimQuestStatusSchema, dateTaskSchema, dateTaskValueSchema, discordTaskSchema, fileTaskSchema, fileTaskValueSchema, invitesTaskSchema, nftTaskSchema, numberTaskSchema, numberTaskValueSchema, onChainTaskSchema, opinionTaskSchema, opinionTaskValueSchema, partnershipTaskSchema, pollTaskValueSchema, postQuestTwitterSpaceTaskSchema, quizTaskSchema, quizTaskValueSchema, statusWithErrorSchema, telegramTaskSchema, textTaskSchema, textTaskValueSchema, tiktokTaskSchema, tiktokTaskValueSchema, tokenTaskSchema, tweetReactTaskSchema, tweetReactTaskValueSchema, tweetTaskSchema, tweetTaskValueSchema, twitterFollowTaskSchema, twitterSpaceTaskValueSchema, urlTaskSchema, urlTaskValueSchema, visitLinkTaskSchema, } from '@zealy/utils';
import { pollTaskSchema } from '../template/baseQuest';
const taskSchema = z.intersection(z.object({
    id: z.string(),
}), z.discriminatedUnion('type', [
    partnershipTaskSchema,
    onChainTaskSchema,
    apiTaskSchema,
    textTaskSchema.merge(textTaskValueSchema),
    urlTaskSchema.merge(urlTaskValueSchema),
    invitesTaskSchema,
    quizTaskSchema.merge(quizTaskValueSchema),
    visitLinkTaskSchema,
    fileTaskSchema.merge(fileTaskValueSchema),
    dateTaskSchema.merge(dateTaskValueSchema),
    numberTaskSchema.merge(numberTaskValueSchema),
    pollTaskSchema.merge(pollTaskValueSchema),
    opinionTaskSchema.merge(opinionTaskValueSchema),
    tweetTaskSchema.merge(tweetTaskValueSchema),
    tiktokTaskSchema.merge(tiktokTaskValueSchema),
    tokenTaskSchema.merge(augmentedTokenTaskSchema),
    nftTaskSchema.merge(augmentedNftTaskSchema),
    postQuestTwitterSpaceTaskSchema
        .merge(augmentedTwitterSpaceTaskSchema)
        .merge(twitterSpaceTaskValueSchema),
    tweetReactTaskSchema.merge(augmentedTweetReactTaskSchema).merge(tweetReactTaskValueSchema),
    telegramTaskSchema.merge(augmentedTelegramTaskSchema),
    discordTaskSchema.merge(augmentedDiscordTaskSchema),
    twitterFollowTaskSchema.merge(augmentedTwitterFollowTaskSchema),
]));
export const testClaimQuestPathSchema = z.object({
    subdomain: z.string(),
});
export const testClaimQuestBodySchema = z.object({
    // we need the settings, the metadata and the value
    tasks: z.array(taskSchema),
    quest: z.object({
        id: z.string(), // the real questId if it exists, or 'save-quest-to-generate' if it doesn't
    }),
    user: z.object({
        id: z.string(), // they can either manually enter one or we generate one
        addresses: z.record(z.string()),
        accounts: z.object({
            email: z.string().optional(),
            tiktok: z.string().optional(),
            discord: z
                .object({
                id: z.string(),
                handle: z.string(),
            })
                .optional(),
            twitter: z
                .object({
                id: z.string(),
                username: z.string(),
            })
                .optional(),
            'zealy-connect': z.string().optional(),
        }),
    }),
});
export const testClaimQuestOutputSchema = z.object({
    status: claimQuestStatusSchema,
    taskValidations: z.array(statusWithErrorSchema.and(z.object({
        taskId: z.string().uuid(),
    }))),
});
