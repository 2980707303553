import { router } from '../../utils';
import { createQuest } from './createQuest';
import { listQuests } from './listQuests';
import { updateQuest } from './updateQuest';
export const publicQuestContract = router({
    listQuests: listQuests,
    createQuest: createQuest,
    updateQuest: updateQuest,
}, {
    pathPrefix: '/communities/:subdomain/quests',
});
