import { partnershipSettingsSchemaInput } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const updateCommunitySettingsPartnerships = mutation({
    method: 'PATCH',
    path: '/:subdomain/settings/partnerships',
    body: partnershipSettingsSchemaInput,
    responses: {
        204: noBody(),
    },
});
