import { generateDetailsInputSchema, generateDetailsOutputSchema } from '@zealy/schemas';
import { mutation } from '../../utils';
export const generateDetails = mutation({
    method: 'POST',
    path: '/generate-details',
    body: generateDetailsInputSchema,
    responses: {
        200: generateDetailsOutputSchema,
    },
});
