import { z } from 'zod';
export const createUserCommunityBodySchema = z.object({
// add body
});
export const createUserCommunityPathSchema = z.object({
// add path params : have to be the same name as path in TRCP
});
export const createUserCommunityInputSchema = createUserCommunityPathSchema.merge(createUserCommunityBodySchema);
export const createUserCommunityOutputSchema = z.object({
// add response
});
