import { getQuestVoteFeedOutputSchema, getQuestVoteFeedPathSchema, getQuestVoteFeedQuerySchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const getQuestVoteFeed = query({
    method: 'GET',
    path: '/:questId/rewards/:rewardId/votes',
    pathParams: getQuestVoteFeedPathSchema,
    query: getQuestVoteFeedQuerySchema,
    responses: {
        200: getQuestVoteFeedOutputSchema,
    },
});
