import { z } from 'zod';

export const generateRedirectUrlForApiQuestTaskOutputSchema = z.object({
  redirectUrl: z.string(),
});

export const generateRedirectUrlForApiQuestTaskPathSchema = z.object({
  subdomain: z.string(),
  questId: z.string(),
  taskId: z.string(),
});

export type GenerateRedirectUrlForApiQuestTaskOutput = z.infer<
  typeof generateRedirectUrlForApiQuestTaskOutputSchema
>;
