import { z } from 'zod';
export const createCommunityBodySchema = z.object({
// add body
});
export const createCommunityPathSchema = z.object({
// add path params : have to be the same name as path in TRCP
});
export const createCommunityInputSchema = createCommunityPathSchema.merge(createCommunityBodySchema);
export const createCommunityOutputSchema = z.object({
// add response
});
