import { z } from 'zod';

import { nftTaskSettingsNetworkSchema, tokenTaskSettingsNetworkSchema } from '@zealy/utils';

export const nonEmptyStringSchema = z.string().trim().min(1);
export const positiveIntSchema = z.number().int().positive();
export const nonNegativeIntSchema = z.number().int().nonnegative();
export const positiveNumberSchema = z.number().positive();
export const contractAddressSchema = z.string().regex(/^0x[a-fA-F0-9]{40}$/);
// todo regex instead of url() to match our s3 path
export const s3UrlSchema = z.string().url();

export const taskValidationErrorCodeSchema = z.enum([
  'INVALID_TASK',
  'DISCORD_USER_NOT_IN_SERVER',
  'WRONG_ANSWER',
  'WRONG_ANSWERS',
  'OPINION_FAILED',
  'INVITE_FAILED',
  'API_FAILED',
  'TWEET_REACT_FAILED',
  'WRONG_PASSWORD',
  'TWEET_WORDS_NOT_INCLUDED',
  'TWEET_EMPTY',
  'TWEET_TOO_LONG',
  'TWEET_URL_INVALID',
  'TWEET_REPLY_NOT_FOUND',
  'TWEET_USERNAME_MISMATCH',
  'TWITTER_FOLLOW_FAILED',
  'TIKTOK_URL_INVALID',
  'TIKTOK_USERNAME_MISMATCH',
  'TIKTOK_VIDEO_NOT_FOUND',
  'TIKTOK_WORDS_NOT_INCLUDED',
  'TIKTOK_NOT_ENOUGH_VIEWS',
  'WALLET_NOT_LINKED',
  'NFT_NOT_FOUND',
  'NFT_BALANCE_NOT_ENOUGH',
  'TOKEN_NOT_FOUND',
  'TOKEN_BALANCE_NOT_ENOUGH',
  'PARTNERSHIP_COMMUNITY_NOT_FOUND',
  'PARTNERSHIP_USER_NOT_IN_COMMUNITY',
]);
export const categoryStatusSchema = z.enum(['open', 'locked', 'claimable', 'completed']);
export const recurrenceSchema = z.enum(['daily', 'weekly', 'monthly', 'once']);
export const rewardTypeSchema = z.enum(['xp', 'role', 'other', 'nft']);
export const claimQuestStatusSchema = z.enum(['success', 'error', 'inReview']);
export const scaleTypeSchema = z.enum(['numerical', 'stars']);
export const taskTypeSchema = z.enum([
  'api',
  'text',
  'discord',
  'url',
  'telegram',
  'quiz',
  'invites',
  'visitLink',
  'file',
  'date',
  'number',
  'poll',
  'opinion',
  'twitterFollow',
  'twitterSpace',
  'tweetReact',
  'tweet',
  'tiktok',
  'onChain',
  'nft',
  'token',
  'partnership',
]);
export const conditionOperatorSchema = z.enum(['AND', 'OR']);
export const conditionQuestOperatorSchema = z.enum(['isCompleted', 'isNotCompleted']);
export const conditionNFTChainSchema = z.enum(['polygon']);
export const rewardNFTChainSchema = z.enum(['polygon-mainnet']);
export const conditionStrictComparisonOperatorSchema = z.enum(['>', '<']);
export const conditionEqualityOperatorSchema = z.enum(['=', '!=']);
export const conditionTypeSchema = z.enum([
  'quest',
  'level',
  'role',
  'date',
  'maxClaims',
  'nft',
  'token',
  'maxClaims',
]);

export const twitterTaskSettingsReactEnum = z.enum(['like', 'retweet', 'reply']);

export const conditionQuestSchema = z.object({
  type: z.literal(conditionTypeSchema.enum.quest),
  operator: conditionQuestOperatorSchema,
  value: z.string().uuid(),
});

export const conditionLevelSchema = z.object({
  type: z.literal(conditionTypeSchema.enum.level),
  operator: conditionStrictComparisonOperatorSchema,
  value: z.preprocess(a => {
    if (typeof a === 'string' && a) {
      return parseInt(z.string().parse(a), 10);
    }
    return a;
  }, positiveIntSchema),
});

export const conditionRoleSchema = z.object({
  type: z.literal(conditionTypeSchema.enum.role),
  operator: conditionEqualityOperatorSchema,
  value: nonEmptyStringSchema,
});

export const conditionDateSchema = z.object({
  type: z.literal(conditionTypeSchema.enum.date),
  operator: conditionStrictComparisonOperatorSchema,
  value: z.string().datetime(),
});

export const conditionMaxClaimsSchema = z.object({
  type: z.literal(conditionTypeSchema.enum.maxClaims),
  value: positiveIntSchema,
});

export const conditionNFTSchema = z.object({
  type: z.literal(conditionTypeSchema.enum.nft),
  chain: conditionNFTChainSchema,
  contractAddress: contractAddressSchema,
  value: z.preprocess(a => {
    if (typeof a === 'string' && a) {
      return parseInt(z.string().parse(a), 10);
    }
    return a;
  }, positiveIntSchema),
});

export const conditionTokenSchema = z.object({
  type: z.literal(conditionTypeSchema.enum.token),
  chain: conditionNFTChainSchema,
  contractAddress: contractAddressSchema,
  value: positiveNumberSchema,
});

export const conditionSchema = z.discriminatedUnion('type', [
  conditionQuestSchema,
  conditionLevelSchema,
  conditionRoleSchema,
  conditionDateSchema,
  conditionNFTSchema,
]);

export type ConditionType = z.infer<typeof conditionSchema>;

export const retryAfterSchema = z.preprocess(a => {
  if (typeof a === 'string' && a) {
    return parseInt(z.string().parse(a), 10);
  }
  return a;
}, z.literal(0).or(z.literal(60)).or(z.literal(300)).or(z.literal(1800)).or(z.literal(3600)).or(z.literal(86400)).or(z.literal(604800)).or(z.literal(2592000)).or(z.literal(-1)));

export const rewardXPSchema = z.object({
  type: z.literal(rewardTypeSchema.enum.xp),
  value: z.union([
    z.coerce.number().int().positive().max(999999999999999),
    z.coerce.number().int().negative().min(-999999999999999),
  ]),
});

export const rewardOtherSchema = z.object({
  type: z.literal(rewardTypeSchema.enum.other),
  value: nonEmptyStringSchema,
});

export const rewardRoleInputSchema = z.object({
  type: z.literal(rewardTypeSchema.enum.role),
  value: nonEmptyStringSchema,
});

export const rewardRoleOutputSchema = z.object({
  type: z.literal(rewardTypeSchema.enum.role),
  value: nonEmptyStringSchema,
  roleName: nonEmptyStringSchema.optional(),
});

export const baseRewardMethodFcfsSchema = z.object({
  type: z.literal('fcfs'),
  max: z.coerce.number().min(0).max(1000).default(0),
  count: z.number().int().optional(),
});

export const baseRewardMethodRaffleSchema = z.object({
  type: z.literal('raffle'),
  amount: z.coerce.number().min(0).max(1000).default(0),
});

export const baseRewardMethodAllSchema = z.object({
  type: z.literal('all'),
});

export const baseRewardMethodSchema = z.discriminatedUnion('type', [
  baseRewardMethodFcfsSchema,
  baseRewardMethodAllSchema,
  baseRewardMethodRaffleSchema,
]);

export const basePartnershipTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.partnership),
});

export const baseOnChainTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.onChain),
});

export const baseNFTTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.nft),
});

export const baseTokenTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.token),
});

export const baseApiTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.api),
});

export const baseTextTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.text),
});

export const baseUrlTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.url),
});

export const baseDiscordTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.discord),
});

export const baseInvitesTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.invites),
});

export const baseTelegramTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.telegram),
});

export const baseQuizTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.quiz),
});

export const baseTiktokTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.tiktok),
});

export const baseVisitLinkTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.visitLink),
});

export const baseFileTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.file),
});

export const baseDateTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.date),
});

export const baseNumberTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.number),
});

export const basePollTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.poll),
});

export const baseOpinionTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.opinion),
});

export const baseTwitterFollowTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.twitterFollow),
});

export const baseTwitterSpaceTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.twitterSpace),
});

export const baseTweetReactTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.tweetReact),
});

export const baseTweetTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.tweet),
});

export const baseInstructionSettingsSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
});

export const discordTaskSchema = baseDiscordTaskSchema.extend({
  settings: z.object({
    inviteUrl: z.string().regex(/^http(s)?:\/\/discord\.(?:gg|com)\/(invite\/)?[a-zA-Z0-9-]+$/i),
  }),
});

export const tiktokTaskSchema = baseTiktokTaskSchema.extend({
  settings: z.object({
    descriptionWords: z.array(z.string()),
    minViewCount: z.coerce.number().int().nonnegative(),
  }),
});

export const partnershipTaskSchema = basePartnershipTaskSchema.extend({
  settings: z.object({
    subdomain: z.string().min(1),
  }),
});

export const onChainTaskSchema = baseOnChainTaskSchema.extend({
  settings: baseInstructionSettingsSchema.extend({
    endpoint: z.string().url().startsWith('https', 'Endpoint must start with https'),
    query: z.string(),
  }),
});

export const tokenTaskSchema = baseTokenTaskSchema.extend({
  settings: tokenTaskSettingsNetworkSchema.and(
    z.object({
      minBalance: z.coerce.number().nonnegative(),
    }),
  ),
});

export const nftTaskSchema = baseNFTTaskSchema.extend({
  settings: nftTaskSettingsNetworkSchema.and(
    z.object({
      minBalance: z.coerce.number().nonnegative(),
    }),
  ),
});

export const identificationsApiTaskSchema = z.array(
  z.enum(['email', 'twitter', 'discord', 'wallet', 'zealy-connect']),
);

export const invitesTaskSchema = baseInvitesTaskSchema.extend({
  settings: z.object({
    minInviteUserCount: z.coerce.number().int().positive().max(1000),
  }),
});

export const visitLinkTaskSchema = baseVisitLinkTaskSchema.extend({
  settings: z.object({
    linkUrl: z.string().url(),
  }),
});

export const fileTaskSettingsTypeRestrictionSchema = z.discriminatedUnion('restrictFileType', [
  z.object({
    restrictFileType: z.literal(false),
  }),
  z.object({
    restrictFileType: z.literal(true),
    fileTypes: z.array(
      z.enum([
        'document',
        'presentation',
        'spreadsheet',
        'drawing',
        // TODO to remove after PDF-removal migration is finished
        'pdf',
        'image',
        'video',
        'audio',
        'archive',
      ]),
    ),
  }),
]);

export const fileTaskSchema = baseFileTaskSchema.extend({
  settings: fileTaskSettingsTypeRestrictionSchema.and(
    z.object({
      autoValidated: z.boolean().optional().default(false),
      maxFileCount: z.coerce.number().int().positive(),
      maxFileSize: positiveIntSchema,
    }),
  ),
});

const opinionScaleTypeSettingsSchema = z.discriminatedUnion('scaleType', [
  z.object({
    scaleType: z.literal(scaleTypeSchema.enum.numerical),
    step: z.object({
      start: z.coerce.number().int().positive().min(-10),
      end: z.coerce.number().int().positive().max(100),
    }),
    label: z
      .object({
        start: z.string().optional(),
        end: z.string().optional(),
        middle: z.string().optional(),
      })
      .optional(),
  }),
  z.object({
    scaleType: z.literal(scaleTypeSchema.enum.stars),
    starCount: z.coerce.number().int().positive(),
  }),
]);

export const opinionTaskSchema = baseOpinionTaskSchema.extend({
  settings: baseInstructionSettingsSchema.and(opinionScaleTypeSettingsSchema),
});

export const telegramTaskSchema = baseTelegramTaskSchema.extend({
  settings: z.object({
    inviteUrl: z
      .string()
      .url()
      .startsWith('https://t.me/')
      .or(z.string().startsWith('https://telegram.me/')),
  }),
});

export const baseQuestionTaskSettingsSchema = baseInstructionSettingsSchema.extend({
  multipleSelection: z.boolean().default(false),
  shuffleOptionsOrder: z.boolean().optional(),
  verticalAlignement: z.boolean().optional(),
});

export const twitterFollowTaskSchema = baseTwitterFollowTaskSchema.extend({
  settings: z.object({
    // https://help.twitter.com/en/managing-your-account/twitter-username-rules#error
    username: z.string().regex(/^([A-Za-z0-9_]{1,15})$/),
  }),
});

export const baseTwitterSpaceTaskSettingsSchema = z.object({
  spaceUrl: z.string().url(),
});

export const twitterSpaceTaskSchema = baseTwitterSpaceTaskSchema.extend({
  settings: baseTwitterSpaceTaskSettingsSchema.extend({
    protected: z.boolean(),
  }),
});

export const tweetReactTaskSchema = baseTweetReactTaskSchema.extend({
  settings: z.object({
    actions: z.array(twitterTaskSettingsReactEnum).min(1),
    tweetUrl: z.string().url(),
  }),
});

export const tweetTaskSchema = baseTweetTaskSchema.extend({
  settings: z
    .object({
      defaultTweet: z.string().optional(),
      tweetWords: z.array(z.string()),
    })
    .refine(
      value => {
        if (value.defaultTweet && value.tweetWords.length) {
          const lowerCaseTweet = value.defaultTweet?.toLowerCase();
          const allWordsIncluded = value.tweetWords.every(word =>
            lowerCaseTweet?.includes(word.toLowerCase()),
          );
          return allWordsIncluded;
        }
        return true;
      },
      {
        message: 'All words must be included in the default tweet',
        path: ['defaultTweet'],
      },
    ),
});

// TODO: add back when adding the image options
// export const questionImageTaskSettingsSchema = z.discriminatedUnion('withImage', [
//   z.object({
//     withImage: z.literal(false),
//     options: z
//       .array(
//         z.object({
//           label: z.string(),
//         }),
//       )
//       .min(1),
//   }),
//   z.object({
//     withImage: z.literal(true),
//     options: z
//       .array(
//         z.object({
//           imageUrl: s3UrlSchema,
//           label: z.string(),
//         }),
//       )
//       .min(1),
//   }),
// ]);

export const questionImageTaskSettingsSchema = z.object({
  withImage: z.literal(false).optional().default(false),
  options: z
    .array(
      z.object({
        label: z.string(),
      }),
    )
    .min(1),
});

export const pollTaskSchema = basePollTaskSchema.extend({
  settings: baseQuestionTaskSettingsSchema
    .extend({
      autoValidated: z.boolean().optional(),
      otherOption: z.boolean().optional(),
    })
    .and(questionImageTaskSettingsSchema),
});

export const baseId = z.object({
  id: z.string().uuid(),
});

export const augmentedTokenTaskSchema = z.object({
  metadata: z
    .object({
      name: z.string().nullable(),
      symbol: z.string().nullable(),
      decimals: z.number().nullable(),
      logo: z.string().nullable(),
    })
    .optional(),
});

export const augmentedNftTaskSchema = z.object({
  metadata: z
    .object({
      name: z.string().optional(),
      image: z.string().optional(),
      description: z.string().optional(),
      link: z.string().optional(),
    })
    .optional(),
});

export const augmentedDiscordTaskSchema = z.object({
  metadata: z
    .object({
      guildId: z.string(),
      name: z.string(),
      description: z.string().optional(),
      imageUrl: z.string().url().optional(),
    })
    .optional(),
});

export const augmentedTelegramTaskSchema = z.object({
  metadata: z
    .object({
      name: z.string().optional(),
      description: z.string().optional(),
      imageUrl: z.string().url().optional(),
    })
    .optional(),
});

export const augmentedTweetReactTaskSchema = z.object({
  metadata: z
    .object({
      tweetId: z.string().regex(/^[0-9]+$/),
      content: z.string(),
      createdAt: z.string().datetime().optional(),
      creator: z
        .object({
          id: z.string(),
          username: z.string(),
          name: z.string(),
          imageUrl: z.string().url().optional(),
        })
        .optional(),
    })
    .optional(),
});

export const augmentedTwitterFollowTaskSchema = z.object({
  metadata: z
    .object({
      id: z.string(),
      name: z.string(),
      imageUrl: z.string().url().optional(),
    })
    .optional(),
});

const twitterSpaceStatusEnum = z.enum(['scheduled', 'live', 'ended']);

const twitterSpaceStatusMetadataSchema = z.discriminatedUnion('status', [
  z.object({
    status: z.literal(twitterSpaceStatusEnum.enum.scheduled),
    scheduledAt: z.string().datetime(),
  }),
  z.object({
    status: z.literal(twitterSpaceStatusEnum.enum.ended),
    endedAt: z.string().datetime(),
  }),
  z.object({
    status: z.literal(twitterSpaceStatusEnum.enum.live),
  }),
]);

export const augmentedTwitterSpaceTaskSchema = z.object({
  metadata: z
    .object({
      spaceId: z.string(),
      title: z.string().optional(),
      creator: z.object({
        name: z.string(),
        imageUrl: z.string().url().optional(),
        username: z.string(),
      }),
    })
    .and(twitterSpaceStatusMetadataSchema)
    .optional(),
});

const marksSchema = z.object({
  type: z.string(),
  attrs: z
    .object({
      href: z.string().optional(),
      target: z.enum(['_blank', '_parent', '_self', '_top']).optional(),
      class: z.string().nullable().optional(),
    })
    .passthrough()
    .optional(),
});

const baseDescriptionSchema = z
  .object({
    type: z.string(),
    text: z.string().optional(),
    attrs: z.object({}).passthrough().optional(),
    marks: z.array(marksSchema.passthrough()).optional(),
  })
  .passthrough();

type Description = z.infer<typeof baseDescriptionSchema> & {
  content?: Description[] | undefined;
};

export const descriptionSchema: z.ZodType<Description> = baseDescriptionSchema.extend({
  content: z.lazy(() => z.array(descriptionSchema).optional()),
});

export type TaskType = z.infer<typeof taskTypeSchema>;
export type Condition = z.infer<typeof conditionSchema>;

export type ConditionWithState = Condition & {
  state: {
    isOpen: boolean;
    canChange: boolean;
  };
};
