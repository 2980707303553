import { z } from 'zod';
export const vectorStoreSearchBodySchema = z.object({
    query: z.string().optional(),
    ids: z.array(z.string()).optional(),
});
export const vectorStoreSearchInputSchema = vectorStoreSearchBodySchema;
export const vectorStoreSearchOutputSchema = z.object({
    results: z.array(z.object({
        text: z.string(),
        id: z.string(),
        url: z.string(),
    })),
});
