import { listTokenRewardQuerySchema, listTokenRewardSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const listTokenReward = query({
    method: 'GET',
    path: '',
    query: listTokenRewardQuerySchema,
    responses: {
        200: listTokenRewardSchema,
    },
});
