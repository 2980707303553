import { listReviewEventsOutputSchema, listReviewEventsPathSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const listReviewEvents = query({
    summary: 'List review events for a review',
    method: 'GET',
    path: '/:id/events',
    pathParams: listReviewEventsPathSchema,
    responses: {
        200: listReviewEventsOutputSchema,
    },
});
