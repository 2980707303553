import { createPartnershipRequestInputSchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const createPartnershipRequest = mutation({
    method: 'POST',
    path: '',
    body: createPartnershipRequestInputSchema,
    responses: {
        201: noBody(),
    },
});
