import { z } from 'zod';
export const publicCommunityData = z.object({
    id: z.string(),
    name: z.string().nullable(),
    createdAt: z.string().datetime().nullable(),
    updatedAt: z.string().datetime().nullable(),
    launchDate: z.string().datetime().nullable(),
    subdomain: z.string().nullable(),
    logo: z.string().nullable(),
    image: z.string().nullable(),
    blockchain: z.string(),
    description: z.string().nullable(),
    website: z.string().nullable(),
    twitter: z.string().nullable(),
    discord: z.string().nullable(),
    flag: z.boolean(),
    opensea: z.string().nullable(),
    requiredFields: z
        .object({
        fillEmail: z.boolean(),
        fillWallet: z.boolean(),
        linkDiscord: z.boolean(),
        linkTwitter: z.boolean(),
    })
        .nullable(),
    visibility: z.enum(['public', 'private']),
    certified: z.boolean(),
    sector: z.string().nullable(),
    type: z.string().nullable(),
    whiteLabelConfig: z.record(z.unknown()).nullable(),
    minimumRequiredXp: z.number(),
    v2: z.boolean().nullable(),
    verified: z.boolean(),
    web3: z.boolean().nullable(),
    partnershipsEnabled: z.boolean(),
    partnershipsRequiredMember: z.number(),
    totalMembers: z.number(),
    totalTwitterFollowers: z.number(),
    totalDiscordMembers: z.number(),
    totalQuests: z.number(),
    rank: z.number().optional(),
    subscriptionId: z.string().nullable(),
    featured: z.boolean(),
    deleted: z.boolean().nullable(),
    switchedToV2At: z.string().nullable(),
});
