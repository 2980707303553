import { z } from 'zod';
export const eventSchema = z.object({
    name: z.string(),
    identityId: z.string(),
    createdAt: z.string(),
    ip: z.string().optional(),
    userAgent: z.string().optional(),
    props: z.record(z.any()).optional(),
});
export const createAntibotEventBodySchema = z.object({
    events: z.array(eventSchema),
});
export const createAntibotEventInputSchema = createAntibotEventBodySchema;
//TODO
export const createAntibotEventOutputSchema = z.any();
