import { router } from '../../utils';
import { checkMember } from './checkMember';
import { deleteZealyConnectForMember } from './deleteZealyConnectForMember';
import { getBasicInfo } from './getBasicInfo';
export const publicCommunityContract = router({
    checkMember: checkMember,
    getBasicInfo: getBasicInfo,
    deleteZealyConnectForMember: deleteZealyConnectForMember,
}, {
    pathPrefix: '/communities',
});
