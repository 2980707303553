import { listAvailableEventTypesOutputSchema, listAvailableEventTypesPathSchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const listAvailableEventTypes = query({
    summary: 'List available event types a webhook can subscribe to',
    method: 'GET',
    path: '/:subdomain/webhooks-event-types',
    pathParams: listAvailableEventTypesPathSchema,
    responses: {
        200: listAvailableEventTypesOutputSchema,
    },
});
