import { z } from 'zod';

export const getReviewAlertsPathSchema = z.object({
  id: z.string().uuid(),
  subdomain: z.string(),
});

export const getReviewAlertsInputSchema = getReviewAlertsPathSchema;

const reviewAlertType = z.enum(['reused', 'duplicate']);

const reviewAlert = z.object({
  type: reviewAlertType,
  claims: z.array(
    z.object({
      id: z.string().uuid(),
      user: z.object({
        id: z.string().uuid(),
        name: z.string().nullable(),
        avatar: z.string().nullable(),
      }),
      questId: z.string().uuid(),
    }),
  ),
});

export const getReviewAlertsOutputSchema = z.object({
  items: z.array(reviewAlert),
});

export type GetReviewAlertsInput = z.infer<typeof getReviewAlertsInputSchema>;
export type GetReviewAlertsOutput = z.infer<typeof getReviewAlertsOutputSchema>;
