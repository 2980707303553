import { getAnalyticsPathSchema, getRangeAnalyticsWithIntervalQuerySchema, getTimeSeriesOutputSchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const getActiveUsers = query({
    method: 'GET',
    path: '/active-users',
    pathParams: getAnalyticsPathSchema,
    query: getRangeAnalyticsWithIntervalQuerySchema,
    responses: {
        200: getTimeSeriesOutputSchema,
    },
});
