import { z } from 'zod';
export const getZealyConnectStatusPathSchema = z.object({
    subdomain: z.string(),
    questId: z.string(),
});
export const getZealyConnectStatusOutputSchema = z.object({
    tasks: z.array(z.object({
        id: z.string(),
        connected: z.boolean(),
    })),
});
