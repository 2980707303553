import { seedClaimsBodySchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const seedClaims = mutation({
    method: 'POST',
    path: '/seed-claims',
    body: seedClaimsBodySchema,
    responses: {
        200: noBody(),
    },
});
