import { xpBodySchema, xpPathSchema, xpResponseSchema } from '@zealy/schemas';
import { mutation } from '../../utils';
export const addXp = mutation({
    summary: 'Give XP to a specific user',
    method: 'POST',
    path: '/:userId/xp',
    pathParams: xpPathSchema,
    body: xpBodySchema,
    responses: {
        200: xpResponseSchema,
    },
});
