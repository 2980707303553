import { searchUserCommunitiesOutputSchema, searchUserCommunitiesPathSchema, searchUserCommunitiesQuerySchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const searchUserCommunities = query({
    method: 'GET',
    path: '/:id/communities/search',
    pathParams: searchUserCommunitiesPathSchema,
    query: searchUserCommunitiesQuerySchema,
    responses: {
        200: searchUserCommunitiesOutputSchema,
    },
});
