import { listPromotionBookingQuerySchema, listPromotionBookingSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const listPromotionBooking = query({
    method: 'GET',
    path: '',
    query: listPromotionBookingQuerySchema,
    responses: {
        200: listPromotionBookingSchema,
    },
});
