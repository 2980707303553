import { z } from 'zod';
import { aiCommunityManagerContract } from './api/aiCommunityManager';
import { analyticsContract } from './api/analytics';
import { antibotContract } from './api/antibot';
import { authContract } from './api/auth';
import { blockchainContract } from './api/blockchain';
import { communityContract } from './api/community';
import { communityMemberContract } from './api/communityMember';
import { communitySecretContract } from './api/communitySecret';
import { communityWebhookContract } from './api/communityWebhook';
import { devContract } from './api/dev';
import { invitationContract } from './api/invitation';
import { leaderboardContract } from './api/leaderboard';
import { leaderboardSprintContract } from './api/leaderboardSprint';
import { magicQuestContract } from './api/magicQuestContract';
import { notificationContract } from './api/notification';
import { partnershipContract } from './api/partnership';
import { promotionBookingContract } from './api/promotionBooking';
import { questContract } from './api/quest';
import { reviewContract } from './api/review';
import { rewardContract } from './api/reward';
import { subscriptionContract } from './api/subscription';
import { supportContract } from './api/support';
import { templateContract } from './api/template';
import { tokenRewardContract } from './api/tokenReward';
import { userContract } from './api/user';
import { errors } from './errors';
import { publicCommunityContract } from './public/community';
import { publicCommunityMemberContract } from './public/communityMember';
import { publicCommunityWebhookContract } from './public/communityWebhook';
import { publicLeaderboardContract } from './public/leaderboard';
import { publicModuleContract } from './public/module';
import { publicQuestContract } from './public/quest';
import { publicReviewContract } from './public/review';
import { router } from './utils';
export const contract = router({
    aiCommunityManager: aiCommunityManagerContract,
    analytics: analyticsContract,
    antibot: antibotContract,
    auth: authContract,
    blockchain: blockchainContract,
    community: communityContract,
    communityMember: communityMemberContract,
    communitySecret: communitySecretContract,
    communityWebhook: communityWebhookContract,
    dev: devContract,
    invitation: invitationContract,
    leaderboard: leaderboardContract,
    leaderboardSprint: leaderboardSprintContract,
    magicQuest: magicQuestContract,
    notification: notificationContract,
    partnership: partnershipContract,
    promotionBooking: promotionBookingContract,
    quest: questContract,
    review: reviewContract,
    reward: rewardContract,
    subscription: subscriptionContract,
    support: supportContract,
    template: templateContract,
    tokenReward: tokenRewardContract,
    user: userContract,
}, {
    pathPrefix: '/api',
    commonResponses: errors,
});
export const publicContract = router({
    community: publicCommunityContract,
    communityMember: publicCommunityMemberContract,
    communityWebhook: publicCommunityWebhookContract,
    leaderboard: publicLeaderboardContract,
    quest: publicQuestContract,
    review: publicReviewContract,
    module: publicModuleContract,
}, {
    pathPrefix: '/public',
    commonResponses: errors,
    baseHeaders: z.object({
        'x-api-key': z.string(),
    }),
});
