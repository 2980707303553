import { z } from 'zod';

import { reviewSchema } from '../reviews';

export const getQuestVoteResultsOutputSchema = z.object({
  items: z.array(
    reviewSchema
      .omit({ quest: true, autoValidated: true })
      .partial({ tasks: true })
      .merge(
        z.object({
          userVoteStatus: z.enum(['upvote', 'downvote']).optional(),
          upvotes: z.number(),
          downvotes: z.number(),
          score: z.number().optional(),
        }),
      ),
  ),
  nextCursor: z.string().nullable(),
});

export const getQuestVoteResultsPathSchema = z.object({
  subdomain: z.string(),
  questId: z.string().uuid(),
  rewardId: z.string().uuid(),
});

export const getQuestVoteResultsQuerySchema = z.object({
  limit: z.coerce.number().int().positive().max(1000).default(20),
  cursor: z.string().optional().nullable(),
});

export type GetQuestVoteResultsOutput = z.infer<typeof getQuestVoteResultsOutputSchema>;
