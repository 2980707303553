import { z } from 'zod';
import { networkSchema } from '@zealy/utils';
const notificationTypeSchema = z.enum([
    'review',
    'invite-accepted',
    'manual-xp',
    'raffle-reward-distributed',
    'partnership-accepted',
    'partnership-requested',
    'top-reward-distributed',
    'vote-reward-distributed',
    'admin-quest-reward-distributed',
    'token-reward-sent',
]);
const baseNotificationSchema = z.object({
    id: z.string().uuid(),
    read: z.boolean(),
    createdAt: z.string().datetime(),
});
const createdByUserSchema = z.object({
    id: z.string().uuid(),
    name: z.string().nullable(),
    avatar: z.string().nullable(),
    type: z.literal('user').optional(),
});
const createdByCommunitySchema = z.object({
    id: z.string().uuid(),
    name: z.string().nullable(),
    avatar: z.string().nullable(),
    subdomain: z.string(),
    type: z.literal('community'),
});
const reviewNotificationDataSchema = z.object({
    status: z.enum(['pending', 'success', 'fail']),
    mark: z.enum(['flag', 'star']).nullable(),
    comment: z.string().nullable(),
    bonusXP: z.number().nullable().optional(),
    claimId: z.string().nullish(),
    resource: z.object({
        id: z.string().uuid(),
        name: z.string(),
        categoryId: z.string().uuid(),
    }),
    createdBy: createdByUserSchema,
});
const reviewNotificationSchema = z.object({
    type: z.literal(notificationTypeSchema.enum.review),
    data: reviewNotificationDataSchema,
});
const inviteAcceptedNotificationDataSchema = z.object({
    createdBy: createdByUserSchema,
});
const partnershipRequestedNotificationDataSchema = z.object({
    createdBy: createdByUserSchema,
    message: z.string(),
    communityName: z.string(),
});
const inviteAcceptedNotificationSchema = z.object({
    type: z.literal(notificationTypeSchema.enum['invite-accepted']),
    data: inviteAcceptedNotificationDataSchema,
});
const partnershipAcceptedNotificationSchema = z.object({
    type: z.literal(notificationTypeSchema.enum['partnership-accepted']),
    data: inviteAcceptedNotificationDataSchema,
});
const partnershipRequestedNotificationSchema = z.object({
    type: z.literal(notificationTypeSchema.enum['partnership-requested']),
    data: partnershipRequestedNotificationDataSchema,
});
const manualXpNotificationDataSchema = z.object({
    value: z.number(),
    comment: z.string().nullable(),
    createdBy: createdByUserSchema,
});
const manualXpNotificationSchema = z.object({
    type: z.literal(notificationTypeSchema.enum['manual-xp']),
    data: manualXpNotificationDataSchema,
});
const roleRewardSchema = z.object({
    type: z.literal('role'),
    roleName: z.string().optional(),
    roleId: z.string(),
});
const xpRewardSchema = z.object({
    type: z.literal('xp'),
    value: z.number(),
});
const tokenRewardSchema = z.object({
    type: z.literal('token'),
    value: z.number(),
    contract: z.string(),
    network: networkSchema,
    symbol: z.string().nullable(),
    address: z.string(),
});
const otherRewardSchema = z.object({
    type: z.literal('other'),
    description: z.string(),
});
const notificationRewardDistributedDataSchema = z
    .object({
    resource: z.object({
        quest: z.object({
            id: z.string(),
            name: z.string(),
            categoryId: z.string().uuid(),
        }),
    }),
    createdBy: createdByCommunitySchema,
})
    .and(z.discriminatedUnion('type', [
    roleRewardSchema,
    xpRewardSchema,
    tokenRewardSchema,
    otherRewardSchema,
]));
const rewardRaffleDistributedSchema = z.object({
    type: z.literal(notificationTypeSchema.enum['raffle-reward-distributed']),
    data: notificationRewardDistributedDataSchema,
});
const rewardTopDistributedSchema = z.object({
    type: z.literal(notificationTypeSchema.enum['top-reward-distributed']),
    data: notificationRewardDistributedDataSchema,
});
const rewardVoteDistributedSchema = z.object({
    type: z.literal(notificationTypeSchema.enum['vote-reward-distributed']),
    data: notificationRewardDistributedDataSchema,
});
const rewardMethodTypeSchema = z.enum(['fcfs', 'raffle', 'top']);
const adminQuestRewardMethodSchema = z.object({
    type: rewardMethodTypeSchema,
});
const adminQuestRewardDistributedSchema = z.object({
    type: z.literal(notificationTypeSchema.enum['admin-quest-reward-distributed']),
    data: z
        .object({
        nbWinners: z.number(),
        resource: z.object({
            rewardMethod: adminQuestRewardMethodSchema,
            quest: z.object({
                id: z.string(),
                name: z.string(),
            }),
        }),
        createdBy: createdByCommunitySchema,
    })
        .and(z.discriminatedUnion('type', [roleRewardSchema, xpRewardSchema, otherRewardSchema])),
});
const tokenRewardSentSchema = z.object({
    type: z.literal(notificationTypeSchema.enum['token-reward-sent']),
    data: z.object({
        value: z.number(),
        contract: z.string(),
        network: networkSchema,
        symbol: z.string().nullable(),
        address: z.string(),
        resource: z.object({
            quest: z.object({
                id: z.string(),
                name: z.string(),
                categoryId: z.string().uuid(),
            }),
        }),
        createdBy: createdByCommunitySchema,
    }),
});
const notificationSchema = baseNotificationSchema.and(z.discriminatedUnion('type', [
    reviewNotificationSchema,
    inviteAcceptedNotificationSchema,
    manualXpNotificationSchema,
    rewardRaffleDistributedSchema,
    rewardTopDistributedSchema,
    rewardVoteDistributedSchema,
    partnershipAcceptedNotificationSchema,
    partnershipRequestedNotificationSchema,
    adminQuestRewardDistributedSchema,
    tokenRewardSentSchema,
]));
export const listNotificationsOutputSchema = z.object({
    items: z.array(notificationSchema),
    nextCursor: z.string().nullable(),
    totalUnread: z.number(),
});
export const listNotificationsPathSchema = z.object({
    subdomain: z.string(),
    userId: z.string().uuid(),
});
export const listNotificationsQuerySchema = z.object({
    limit: z.coerce.number().int().positive().max(100).default(20),
    cursor: z.string().optional(),
});
export const listNotificationsInputSchema = listNotificationsQuerySchema.merge(listNotificationsPathSchema);
