import { getUserCommunitiesOutputSchema, getUserCommunitiesPathSchema, getUserCommunitiesQuerySchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const getUserCommunities = query({
    method: 'GET',
    path: '/:id/communities',
    pathParams: getUserCommunitiesPathSchema,
    query: getUserCommunitiesQuerySchema,
    responses: {
        200: getUserCommunitiesOutputSchema,
    },
});
