import { testApiQuestEndpointBodySchema, testApiQuestEndpointOutputSchema, testApiQuestEndpointPathSchema, } from '@zealy/schemas';
import { mutation } from '../../utils';
export const testApiQuestEndpoint = mutation({
    method: 'POST',
    path: '/api/test',
    pathParams: testApiQuestEndpointPathSchema,
    body: testApiQuestEndpointBodySchema,
    responses: {
        200: testApiQuestEndpointOutputSchema,
    },
});
