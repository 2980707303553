import { z } from 'zod';

import { reviewSchema } from './common';

const JSONSafeParseArray = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return [];
  }
};

const listReviewsPublicApiFilterSchema = z.object({
  questId: z.string().uuid().optional(),
  userId: z.string().uuid().optional(),
  reviewerId: z.string().uuid().optional(),
  status: z.enum(['pending', 'success', 'fail']).optional(),
});

const listReviewsFilterSchema = z.object({
  questId: z.preprocess(
    val => (typeof val === 'string' ? JSONSafeParseArray(val) : val ?? []),
    z.array(z.string().uuid()),
  ),
  userId: z.preprocess(
    val => (typeof val === 'string' ? JSONSafeParseArray(val) : val ?? []),
    z.array(z.string().uuid()),
  ),
  reviewerId: z.preprocess(
    val => (typeof val === 'string' ? JSONSafeParseArray(val) : val ?? []),
    z.array(z.string().uuid()),
  ),
  status: z.preprocess(
    val => (typeof val === 'string' ? JSONSafeParseArray(val) : val ?? []),
    z.array(z.enum(['pending', 'success', 'fail'])),
  ),
  lastSeenStatus: z.enum(['pending', 'success', 'fail']).optional(),
});

const listReviewsSortSchema = z.object({
  sortBy: z.preprocess(
    val => (typeof val === 'string' ? val.split(',') : val ?? []),
    z.array(z.enum(['createdAt', 'updatedAt', 'status'])),
  ),
  sortDirection: z.enum(['asc', 'desc']).optional().default('desc'),
});

export const listReviewsPathSchema = z.object({
  subdomain: z.string(),
});

const listReviewsBaseQuerySchema = z.object({
  limit: z.coerce.number().int().max(102).positive().default(50),
  cursor: z
    .string()
    // Checking that the cursor is a valid ISO date, concatenated with an underscore and a UUID
    .regex(
      /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3,6}Z)_([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/,
      {
        message: 'Invalid cursor format.',
      },
    )
    .optional()
    .nullable(),
});

export const listReviewsQuerySchema = listReviewsBaseQuerySchema
  .merge(listReviewsSortSchema)
  .merge(listReviewsFilterSchema);

export const listReviewsPublicApiQuerySchema = listReviewsBaseQuerySchema.merge(
  listReviewsPublicApiFilterSchema,
);

export const listReviewsPublicApiInputSchema =
  listReviewsPublicApiQuerySchema.merge(listReviewsPathSchema);

export const listReviewsInputSchema = listReviewsQuerySchema.merge(listReviewsPathSchema);

export const listReviewsOutputSchema = z.object({
  items: z.array(reviewSchema),
  nextCursor: z.string().nullable(),
});

export type ListReviewsInput = z.infer<typeof listReviewsInputSchema>;
export type ListReviewsPublicApiInput = z.infer<typeof listReviewsPublicApiInputSchema>;
export type ListReviewsOutput = z.infer<typeof listReviewsOutputSchema>;
