import { listLeaderboardSprintQuestsOutputSchema, listLeaderboardSprintQuestsPathSchema, listLeaderboardSprintQuestsQuerySchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const listLeaderboardSprintQuests = query({
    method: 'GET',
    path: '/communities/:subdomain/leaderboard/sprint/quests',
    pathParams: listLeaderboardSprintQuestsPathSchema,
    query: listLeaderboardSprintQuestsQuerySchema,
    responses: {
        200: listLeaderboardSprintQuestsOutputSchema,
    },
});
