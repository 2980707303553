import { getSubscriptionStatusOutputSchema, getSubscriptionStatusPathSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const getSubscriptionStatus = query({
    method: 'GET',
    path: '/status',
    pathParams: getSubscriptionStatusPathSchema,
    responses: {
        200: getSubscriptionStatusOutputSchema,
    },
});
