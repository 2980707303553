import { z } from 'zod';
export const createCheckoutSessionUrlPathSchema = z.object({
    subdomain: z.string(),
});
export const createCheckoutSessionUrlBodySchema = z.object({
    usagePriceId: z.string().regex(/^price_\w+$/),
    basePriceId: z.string().regex(/^price_\w+$/),
    referralId: z.string().optional(),
});
export const createCheckoutSessionUrlInputSchema = createCheckoutSessionUrlPathSchema.merge(createCheckoutSessionUrlBodySchema);
export const createCheckoutSessionUrlOutputSchema = z.object({
    checkoutUrl: z.string().url(),
});
