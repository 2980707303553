import { z } from 'zod';

export const extractPathFromUrl = (url: string) => {
  const urlObject = new URL(url);
  // Remove the first slash
  return urlObject.pathname.substring(1);
};

// todo regex instead of url() to match our s3 path
export const s3UrlSchema = z.string().url();
export const taskTypeSchema = z.enum([
  'api',
  'text',
  'discord',
  'url',
  'telegram',
  'quiz',
  'invites',
  'visitLink',
  'file',
  'date',
  'number',
  'poll',
  'opinion',
  'twitterFollow',
  'twitterSpace',
  'tweetReact',
  'tweet',
  'tiktok',
  'onChain',
  'nft',
  'token',
  'partnership',
]);

// All task settings are optional because they are stored in the quest table, and are lost if the quest task is updated
// It could be solved by soft deleting tasks in the quests table instead of deleting them
const titleSettingsSchema = z.object({
  title: z.string().nullish(),
});

export const basePartnershipTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.partnership),
});

export const baseOnChainTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.onChain),
});

export const baseTokenTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.token),
});

export const baseNFtTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.nft),
});

export const baseApiTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.api),
});

export const baseTextTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.text),
});

export const baseUrlTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.url),
});

export const baseDiscordTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.discord),
});

export const baseInvitesTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.invites),
});

export const baseTelegramTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.telegram),
});

export const baseQuizTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.quiz),
});

export const baseVisitLinkTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.visitLink),
});

export const baseFileTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.file),
});

export const baseDateTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.date),
});

export const baseNumberTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.number),
});

export const basePollTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.poll),
});

export const baseOpinionTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.opinion),
});

export const baseTwitterFollowTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.twitterFollow),
});

export const baseTwitterSpaceTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.twitterSpace),
});

export const baseTweetReactTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.tweetReact),
});

export const baseTweetTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.tweet),
});

export const baseTiktokTaskSchema = z.object({
  type: z.literal(taskTypeSchema.enum.tiktok),
});

export const textTaskValueSchema = baseTextTaskSchema.extend({
  value: z.string().trim(),
  settings: titleSettingsSchema.or(z.object({})),
});

export const urlTaskValueSchema = baseUrlTaskSchema.extend({
  value: z.string().url(),
  settings: titleSettingsSchema.or(z.object({})),
});

export const quizTaskValueSchema = baseQuizTaskSchema.extend({
  values: z.array(z.string()).min(1),
  settings: titleSettingsSchema.or(z.object({})),
});

export const pollTaskValueSchema = basePollTaskSchema.extend({
  values: z.array(z.string()).min(1),
  settings: titleSettingsSchema.or(z.object({})),
});

export const fileTaskValueSchema = baseFileTaskSchema.extend({
  fileUrls: z.array(s3UrlSchema).min(1),
  // TODO: might need on input  .transform(urls => urls.map(url => extractPathFromUrl(url))),
});

export const dateTaskValueSchema = baseDateTaskSchema.extend({
  value: z.string().datetime(),
  settings: titleSettingsSchema.or(z.object({})),
});

export const tiktokTaskValueSchema = baseTiktokTaskSchema.extend({
  value: z.string(),
});

export const numberTaskValueSchema = baseNumberTaskSchema.extend({
  value: z.number(),
  settings: titleSettingsSchema.or(z.object({})),
});

const scaleTypeSchema = z.enum(['stars', 'numerical']);

const opinionScaleTypeSettingsSchema = z.discriminatedUnion('scaleType', [
  z.object({
    scaleType: z.literal(scaleTypeSchema.enum.numerical),
    step: z.object({
      start: z.number().int().min(-10),
      end: z.number().int().max(100),
    }),
  }),
  z.object({
    scaleType: z.literal(scaleTypeSchema.enum.stars),
    starCount: z.number(),
  }),
]);

export const opinionTaskValueSchema = baseOpinionTaskSchema.extend({
  value: z.number().int(),
  settings: titleSettingsSchema.and(opinionScaleTypeSettingsSchema).or(z.object({})),
});

export const twitterSpaceTaskValueSchema = baseTwitterSpaceTaskSchema.extend({
  password: z.string().optional(),
});

export const tweetTaskValueSchema = baseTweetTaskSchema.extend({
  tweet: z.string().optional(),
});

const tweetMetadata = z.object({
  createdAt: z.string().datetime().optional(),
  updatedAt: z.string().datetime().optional(),
  tweetCreator: z
    .object({
      id: z.string().optional(),
      name: z.string().optional(),
      imageUrl: z.string().optional(),
      username: z.string().optional(),
      followerCount: z.number().optional(),
    })
    .optional(),
  tweetMetrics: z
    .object({
      likeCount: z.number().optional(),
      viewCount: z.number().optional(),
      quoteCount: z.number().optional(),
      replyCount: z.number().optional(),
      retweetCount: z.number().optional(),
      bookmarkCount: z.number().optional(),
    })
    .optional(),
});

export const tweetReactTaskValueSchema = baseTweetReactTaskSchema.extend({
  retweet: z.string().optional(),
  reply: z.string().optional(),
});

const tweetTaskMetadataSchema = tweetTaskValueSchema.merge(tweetMetadata).extend({
  tweetId: z.string().nullish(),
});

const tweetReactTaskMetadataSchema = tweetReactTaskValueSchema.merge(tweetMetadata).extend({
  tweetId: z.string().nullish(),
});

const visitLinkTaskSchema = baseVisitLinkTaskSchema.extend({
  settings: z.object({
    linkUrl: z.string().url().optional(),
  }),
});

const apiTaskSchema = baseApiTaskSchema.extend({
  settings: titleSettingsSchema.or(z.object({})),
  requestId: z.string().optional(),
  response: z.object({}).optional(),
  statusCode: z.number().optional(),
});

export const basedTaskMetaDataSchema = z.discriminatedUnion('type', [
  baseOnChainTaskSchema,
  baseTokenTaskSchema,
  baseNFtTaskSchema,
  basePartnershipTaskSchema,
  apiTaskSchema,
  textTaskValueSchema,
  baseDiscordTaskSchema,
  urlTaskValueSchema,
  baseTelegramTaskSchema,
  quizTaskValueSchema,
  baseInvitesTaskSchema,
  visitLinkTaskSchema,
  fileTaskValueSchema,
  dateTaskValueSchema,
  numberTaskValueSchema,
  pollTaskValueSchema,
  opinionTaskValueSchema,
  baseTwitterFollowTaskSchema,
  twitterSpaceTaskValueSchema,
  tweetReactTaskMetadataSchema,
  tweetTaskMetadataSchema,
  tiktokTaskValueSchema,
]);

export const statusSchema = z.enum(['pending', 'success', 'fail']);
export const taskStatusSchema = z.enum(['error', 'success', 'in-review']);
export const markSchema = z.enum(['star', 'flag']).nullable();

export const reviewTaskSchema = z
  .object({
    id: z.string(),
    createdAt: z.string().datetime(),
    status: taskStatusSchema,
  })
  .and(basedTaskMetaDataSchema);

export type ReviewTaskSchema = z.infer<typeof reviewTaskSchema>;

export const reviewSchema = z.object({
  id: z.string().uuid(),
  user: z.object({
    id: z.string().uuid(),
    name: z.string().nullable(),
    avatar: z.string().nullable(),
  }),
  quest: z.object({
    id: z.string().uuid(),
    name: z.string(),
  }),
  status: statusSchema,
  mark: markSchema,
  lastReviewerId: z.string().uuid().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  tasks: z.array(reviewTaskSchema),
  autoValidated: z.boolean(),
});
