import { testCommunityWebhookEventBodySchema, testCommunityWebhookEventOutputSchema, testCommunityWebhookEventPathSchema, } from '@zealy/schemas';
import { mutation } from '../../utils';
export const testCommunityWebhookEvent = mutation({
    method: 'POST',
    path: '/:subdomain/webhooks/:webhookId/test-event',
    pathParams: testCommunityWebhookEventPathSchema,
    body: testCommunityWebhookEventBodySchema,
    responses: {
        200: testCommunityWebhookEventOutputSchema,
    },
});
