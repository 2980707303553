import { createQuestInputSchema, createQuestOutputSchema } from '@zealy/schemas';
import { mutation } from '../../utils';
export const createQuest = mutation({
    summary: 'Create quest in a community',
    method: 'POST',
    path: '',
    body: createQuestInputSchema,
    responses: {
        200: createQuestOutputSchema,
    },
});
