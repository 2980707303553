import { checkMemberBodySchema, checkMemberPathSchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const checkMember = mutation({
    summary: 'Check if the user is a member of the community',
    method: 'POST',
    path: '/:subdomain/member',
    pathParams: checkMemberPathSchema,
    body: checkMemberBodySchema,
    responses: {
        200: noBody(),
    },
});
