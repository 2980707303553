import { getLeaderboardOutputSchema, getLeaderboardPathSchema, getLeaderboardQuerySchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const getLeaderboard = query({
    summary: 'Get leaderboard for a community',
    method: 'GET',
    path: '',
    pathParams: getLeaderboardPathSchema,
    query: getLeaderboardQuerySchema,
    responses: {
        200: getLeaderboardOutputSchema,
    },
});
