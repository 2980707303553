import { z } from 'zod';

export const verifyZealyConnectCallbackPathSchema = z.object({
  subdomain: z.string(),
  questId: z.string(),
  taskId: z.string(),
});

export const verifyZealyConnectCallbackQuerySchema = z.object({
  identifier: z.string(),
  signature: z.string(),
});
