import { retryCommunityWebhookEventPathSchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const retryCommunityWebhookEvent = mutation({
    method: 'POST',
    path: '/:subdomain/webhooks/:webhookId/events/:eventId/retry',
    pathParams: retryCommunityWebhookEventPathSchema,
    body: noBody(),
    responses: {
        200: noBody(),
    },
});
