import { listReviewsOutputSchema, listReviewsPathSchema, listReviewsQuerySchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const listReviews = query({
    method: 'GET',
    path: '',
    pathParams: listReviewsPathSchema,
    query: listReviewsQuerySchema,
    responses: {
        200: listReviewsOutputSchema,
    },
});
