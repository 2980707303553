import { z } from 'zod';
export const getRangeAnalyticsQuerySchema = z.object({
    startDate: z.string(),
    endDate: z.string(),
});
export const getRangeAnalyticsWithIntervalQuerySchema = z.object({
    startDate: z.string(),
    endDate: z.string(),
    interval: z.enum(['day', 'week', 'month', 'year']),
});
export const getAnalyticsPathSchema = z.object({
    subdomain: z.string(),
});
export const getTimeSeriesOutputSchema = z.object({
    isComputed: z.boolean(),
    data: z
        .array(z.object({
        date: z.string(),
        value: z.number(),
    }))
        .nullable(),
});
