import { banMemberBodySchema, banMemberPathSchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const banMember = mutation({
    method: 'POST',
    path: '/:userId/ban',
    pathParams: banMemberPathSchema,
    body: banMemberBodySchema,
    responses: {
        200: noBody(),
    },
});
