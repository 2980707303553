import { deleteZealyConnectForMemberPathSchema, deleteZealyConnectForMemberQuerySchema, } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const deleteZealyConnectForMember = mutation({
    summary: 'Deletes Zealy Connect for a member',
    method: 'DELETE',
    path: '/:subdomain/users/:userId/zealy_connect',
    query: deleteZealyConnectForMemberQuerySchema,
    pathParams: deleteZealyConnectForMemberPathSchema,
    responses: {
        204: noBody(),
    },
    body: noBody(),
});
