import { z } from 'zod';
export const countInvitesQuerySchema = z.object({
    status: z.enum(['pending', 'consumed', 'active']).optional(),
    invitesQuestId: z.string().uuid().optional(),
});
export const countInvitesPathSchema = z.object({
    subdomain: z.string(),
    userId: z.string().uuid(),
});
export const countInvitesInputSchema = countInvitesQuerySchema.merge(countInvitesPathSchema);
export const countInvitesOutputSchema = z.object({
    count: z.number(),
});
