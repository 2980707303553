import { z } from 'zod';
import { voteItem } from './common';
export const getQuestVoteFeedOutputSchema = z.object({
    items: z.array(voteItem),
    nextCursor: z.string().nullable(),
});
export const getQuestVoteFeedPathSchema = z.object({
    subdomain: z.string(),
    questId: z.string().uuid(),
    rewardId: z.string().uuid(),
});
export const getQuestVoteFeedQuerySchema = z.object({
    limit: z.coerce.number().int().positive().max(1000).default(20),
    cursor: z.string().optional().nullable(),
});
