import { createLeaderboardSprintBodySchema, createLeaderboardSprintOutputSchema, createLeaderboardSprintPathSchema, } from '@zealy/schemas';
import { mutation } from '../../utils';
export const createLeaderboardSprint = mutation({
    method: 'POST',
    path: '/communities/:subdomain/leaderboard/sprint',
    pathParams: createLeaderboardSprintPathSchema,
    body: createLeaderboardSprintBodySchema,
    responses: {
        200: createLeaderboardSprintOutputSchema,
    },
});
