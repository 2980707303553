'use client';
import { useMutation } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { uploadUserFile } from '../users.service';
export const useUserUpload = (url) => {
    const abortRef = useRef(new AbortController());
    const [progress, setProgress] = useState(0);
    const cancel = () => abortRef.current.abort();
    const mutation = useMutation({
        mutationFn: ({ formData }) => {
            cancel();
            abortRef.current = new AbortController();
            setProgress(0);
            return uploadUserFile({
                formData,
                signal: abortRef.current.signal,
                onProgress: ev => setProgress(Math.round((ev.loaded * 100) / ev.total)),
                url,
            });
        },
    });
    return { ...mutation, progress, cancel };
};
