import { vectorStoreSearchBodySchema, vectorStoreSearchOutputSchema } from '@zealy/schemas';
import { mutation } from '../../utils';
export const vectorStoreSearch = mutation({
    method: 'POST',
    path: '/search',
    body: vectorStoreSearchBodySchema,
    responses: {
        200: vectorStoreSearchOutputSchema,
    },
});
