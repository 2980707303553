import { z } from 'zod';
import { BLOCKCHAIN_NETWORKS } from '@zealy/utils';
export const getMemberBySocialIdQuerySchema = z
    .object({
    discordId: z.string().optional(),
    discordHandle: z.string().optional(),
    twitterUsername: z.string().optional(),
    twitterId: z.string().optional(),
    ethAddress: z.string().optional(),
    email: z.string().optional(),
    blockchainNetwork: z.enum(BLOCKCHAIN_NETWORKS).optional(),
    blockchainAddress: z.string().optional(),
})
    .refine(data => (data.blockchainNetwork && data.blockchainAddress) ||
    (!data.blockchainNetwork && !data.blockchainAddress), {
    message: 'blockchainNetwork and blockchainAddress must both be set or both be unset',
    path: ['blockchainAddress'],
});
export const getMemberBySocialIdPathSchema = z.object({
    subdomain: z.string(),
});
export const getMemberBySocialIdOutputSchema = z.object({
    discordHandle: z.string().nullable(),
    tiktokUsername: z.string().nullable(),
    twitterUsername: z.string().nullable(),
    verifiedBlockchainAddresses: z.record(z.string()).optional().nullable(),
    unVerifiedBlockchainAddresses: z.record(z.string()).optional().nullable(),
    connectedWallet: z.string().nullable(),
    email: z.string().nullable(),
    discordId: z.string().nullable(),
    twitterId: z.string().nullable(),
    id: z.string(),
    xp: z.number(),
    name: z.string().nullable(),
    createdAt: z.string().datetime(),
    rank: z.number(),
    invites: z.array(z.object({
        userId: z.string(),
        status: z.enum(['active', 'pending', 'consumed']),
        joinedAt: z.string().datetime().nullable(),
        xp: z.number(),
    })),
    role: z.enum(['admin', 'editor', 'banned', 'guest', 'reviewer']),
    level: z.number(),
    isBanned: z.boolean(),
    karma: z.number(),
    referrerUrl: z.string().nullable(),
    referrerId: z.string().nullable(),
    banReason: z.string().nullable(),
});
