import { z } from 'zod';
export const xpBodySchema = z.object({
    xp: z.number().min(0),
    label: z.string(),
    description: z.string().optional(),
});
export const xpPathSchema = z.object({
    subdomain: z.string(),
    userId: z.string().uuid(),
});
export const xpResponseSchema = z.object({
    xp: z.number(),
    label: z.string(),
    description: z.string().nullable(),
    communityId: z.string(),
    receiverId: z.string(),
    createdAt: z.string().nullable(),
    updatedAt: z.string().nullable(),
    id: z.string(),
    deleted: z.boolean(),
});
