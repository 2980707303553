import { cancelSubscriptionBodySchema, cancelSubscriptionPathSchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const cancelSubscription = mutation({
    method: 'POST',
    path: '/cancel',
    pathParams: cancelSubscriptionPathSchema,
    body: cancelSubscriptionBodySchema,
    responses: {
        200: noBody(),
    },
});
