import { deleteCommunityWebhookPathSchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const deleteCommunityWebhook = mutation({
    method: 'DELETE',
    path: '/:subdomain/webhooks/:webhookId',
    pathParams: deleteCommunityWebhookPathSchema,
    body: noBody(),
    responses: {
        200: noBody(),
    },
});
