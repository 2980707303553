import { getQuestVoteResultsOutputSchema, getQuestVoteResultsPathSchema, getQuestVoteResultsQuerySchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const getQuestVoteResults = query({
    method: 'GET',
    path: '/:questId/rewards/:rewardId/votes/results',
    pathParams: getQuestVoteResultsPathSchema,
    query: getQuestVoteResultsQuerySchema,
    responses: {
        200: getQuestVoteResultsOutputSchema,
    },
});
