import { z } from 'zod';
import {descriptionSchema, taskInputSchema} from "@zealy/utils";

export const generateMagicQuestPathSchema = z.object({
  subdomain: z.string(),
});

export const generateMagicQuestBodySchema = z.object({
  prompt: z.string(),
});

export const generateMagicQuestInputSchema = generateMagicQuestPathSchema.merge(
  generateMagicQuestBodySchema,
);

export const generateMagicQuestOutputSchema = z.object({
  name: z.string().max(100),
  description: descriptionSchema.nullable(),
  tasks: z.array(taskInputSchema),
});

export type GenerateMagicQuestInput = z.infer<typeof generateMagicQuestInputSchema>;
export type GenerateMagicQuestOutput = z.infer<typeof generateMagicQuestOutputSchema>;
