import { router } from '../../utils';
import { cancelSubscription } from './cancelSubscription';
import { createCheckoutSessionUrl } from './createCheckoutSessionUrl';
import { getBillingPortalUrl } from './getBillingPortalUrl';
import { getPricingTable } from './getPricingTable';
import { getSubscription } from './getSubscription';
import { getSubscriptionStatus } from './getSubscriptionStatus';
import { resumeSubscription } from './resumeSubscription';
import { updateSubscription } from './updateSubscription';
export const subscriptionContract = router({
    cancel: cancelSubscription,
    createCheckoutSessionUrl: createCheckoutSessionUrl,
    get: getSubscription,
    getBillingPortalUrl: getBillingPortalUrl,
    getPricingTable: getPricingTable,
    getStatus: getSubscriptionStatus,
    resume: resumeSubscription,
    update: updateSubscription,
}, {
    pathPrefix: '/communities/:subdomain/subscription',
});
