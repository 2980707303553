import { getReviewOutputSchema, getReviewPathSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const getReview = query({
    summary: 'Get a review',
    method: 'GET',
    path: '/:id',
    pathParams: getReviewPathSchema,
    responses: {
        200: getReviewOutputSchema,
    },
});
