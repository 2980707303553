// put common schemas like regex or positiveSchemas to reuse across all schemas
// don't reexport this file or any common files
import { z } from 'zod';

const marksSchema = z.object({
  type: z.string(),
  attrs: z
    .object({
      href: z.string().optional(),
      target: z.enum(['_blank', '_parent', '_self', '_top']).optional(),
      class: z.string().nullable().optional(),
    })
    .passthrough()
    .optional(),
});

const tipTapNode = z
  .object({
    type: z.string().optional(),
    text: z.string().optional(),
    attrs: z.object({}).passthrough().optional(),
    marks: z.array(marksSchema.passthrough()).optional(),
  })
  .passthrough();

type TipTapNode = z.infer<typeof tipTapNode> & {
  content?: TipTapNode[] | undefined;
};

export const tipTapDocumentSchema: z.ZodType<TipTapNode> = tipTapNode.extend({
  content: z.lazy(() => z.array(tipTapNode).optional()),
});

export type TipTapDocument = z.infer<typeof tipTapDocumentSchema>;

export const Location = z
  .string()
  .regex(/^(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i);

export const dateWithoutTime = z.string().regex(/^\d{4}-\d{2}-\d{2}$/);
