import { z } from 'zod';
import { communityWebhookEventType } from './common';
export const testCommunityWebhookEventBodySchema = z.object({
    eventType: communityWebhookEventType,
});
export const testCommunityWebhookEventPathSchema = z.object({
    webhookId: z.string().uuid(),
    subdomain: z.string(),
});
export const testCommunityWebhookEventInputSchema = testCommunityWebhookEventPathSchema.merge(testCommunityWebhookEventBodySchema);
export const testCommunityWebhookEventOutputSchema = z.object({
    status: z.enum(['success', 'failed']),
    data: z.object({
        status: z.number().optional(),
        message: z.string().optional(),
    }),
});
