import { z } from 'zod';
import { communityWebhookEventType } from './common';
export const createCommunityWebhookBodySchema = z.object({
    name: z.string().min(1),
    uri: z.string().url(),
    active: z.boolean(),
    events: z.array(communityWebhookEventType).min(1),
});
export const createCommunityWebhookPathSchema = z.object({
    subdomain: z.string(),
});
export const createCommunityWebhookInputSchema = createCommunityWebhookBodySchema.merge(createCommunityWebhookPathSchema);
export const createCommunityWebhookOutputSchema = z.object({
    id: z.string().uuid(),
});
