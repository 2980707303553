import { getAnalyticsPathSchema, getRangeAnalyticsQuerySchema, getTopReviewersOutputSchema, } from '@zealy/schemas';
import { query } from '../../utils';
export const getTopReviewers = query({
    method: 'GET',
    path: '/top-reviewers',
    pathParams: getAnalyticsPathSchema,
    query: getRangeAnalyticsQuerySchema,
    responses: {
        200: getTopReviewersOutputSchema,
    },
});
