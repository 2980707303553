import { z } from 'zod';
import { tipTapDocumentSchema } from '../common';
import { baseSprintSchema } from './common';
export const updateLeaderboardSprintBodySchema = z.object({
    endingAt: z.string().optional(),
    startingAt: z.string().optional(),
    rewards: z.union([tipTapDocumentSchema, z.object({})]),
    includedQuestIds: z.array(z.string().uuid()).optional(),
    rewardZone: z.number().nullish(),
    id: z.string().uuid(),
    name: z.string().optional().nullable(),
    description: z.string().max(3000).optional().nullable(),
    image: z.string().optional().nullable(),
    color: z.string().optional().nullable(),
});
export const updateLeaderboardSprintPathSchema = z.object({
    subdomain: z.string(),
});
export const updateLeaderboardSprintInputSchema = updateLeaderboardSprintBodySchema.merge(updateLeaderboardSprintPathSchema);
export const updateLeaderboardSprintOutputSchema = baseSprintSchema;
