import { getAnalyticsPathSchema, getTotalMembersOutputSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const getTotalMembers = query({
    method: 'GET',
    path: '/total-members',
    pathParams: getAnalyticsPathSchema,
    responses: {
        200: getTotalMembersOutputSchema,
    },
});
