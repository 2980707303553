import { z } from 'zod';
export const cancelSubscriptionPathSchema = z.object({
    subdomain: z.string(),
});
export const cancelSubscriptionBodySchema = z.object({
    reason: z.enum([
        'still_evaluating',
        'switch_anual_plan',
        'technical_issues',
        'too_expensive',
        'not_useful',
        'inadequate_support',
        'other',
    ]),
    disappointmentLevel: z.enum([
        'extremely_disappointed',
        'quite_disappointed',
        'somewhat_disappointed',
        'not_so_disappointed',
        'not_disappointed_at_all',
    ]),
    thingDoneDifferently: z.string().optional(),
    interestedToGetInTouch: z.boolean().default(false),
});
export const cancelSubscriptionInputSchema = cancelSubscriptionBodySchema.merge(cancelSubscriptionPathSchema);
