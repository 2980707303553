import { none, twitterOAuth2CallbackInputSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const twitterOAuth2Callback = query({
    method: 'GET',
    path: '/oauth2/twitter/callback',
    query: twitterOAuth2CallbackInputSchema,
    responses: {
        302: none,
    },
});
